import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

const restLink_user = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
 });
const account_client = new ApolloClient({
  link: restLink_user,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateUser($id: Int!, $name: String!, $mail: String!, $uid: String!, $profile_id: Int!){
    updateUser(id:$id, input:{name:$name, mail:$mail, uid:$uid, profile_id:$profile_id}){
      id
    }
  }
`;

const GET_USER = gql`
  query getUser($user_id: Int!){
    user(id:$user_id){
      name, mail, uid, profile{value:id, label:title}
    }
  }`;



class UserItem extends Component {

  user_id;
  user_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: ""
    };
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.user_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.parent_id = (this.props.match.params.parent_id ? this.props.match.params.parent_id : 0);


    return (


      <Query
      client={account_client}
      query={GET_USER}
      variables={{user_id:this.user_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.user_edit = data.user;

        return (

      <Mutation mutation={ADD_TODO} client={account_client}>
      {(createOrUpdateUser, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/User"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Usuário"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateUser({variables: { 
                      id: this.user_id, 
                      name: e.target.elements.name.value,
                      mail: e.target.elements.mail.value,
                      uid: e.target.elements.uid.value,
                      profile_id: (e.target.elements.profile_id.value ? parseInt(e.target.elements.profile_id.value) : 0)
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "name",
                        label: "Nome",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome",
                        defaultValue: (this.user_edit ? this.user_edit.name : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "mail",
                        label: "E-mail",
                        type: "email",
                        bsClass: "form-control",
                        placeholder: "mail",
                        defaultValue: (this.user_edit ? this.user_edit.mail : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "uid",
                        label: "User ID (UID)",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "User ID (UID)",
                        defaultValue: (this.user_edit ? this.user_edit.uid : "")
                      }
                    ]}
                  />

                  <FormGroup>
                    <ControlLabel>Perfil</ControlLabel>
                    <div>
                      <Query
                          client={account_client}
                          query={gql`{
                            profiles_collections{
                              profiles{
                                value:id, label:title
                              }
                            }
                          }`}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                            return (
                              <Select
                                name="profile_id"
                                options={data.profiles_collections ? data.profiles_collections.profiles : []}
                                className="basic-select"
                                classNamePrefix="select"
                                isClearable={true}
                                defaultValue={this.user_edit && this.user_edit ? this.user_edit.profile : ""}
                              />
                              );
                            }}
                      </Query>
                    </div>
                  </FormGroup>
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default UserItem;
