import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 


import NpsImg1 from "../../assets/img/smile-1.png";
import NpsImg2 from "../../assets/img/smile-2.png";
import NpsImg3 from "../../assets/img/smile-3.png";

const restLink_chat = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const chat_client = new ApolloClient({
  link: restLink_chat,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

function getSiteUrl($param,$id) {
  return "/#/"+$param+"/"+$id;
}

const DELETE_CHAT = gql`
  mutation deletechat($id: Int!){
    deleteChat(id:$id) {
      id
    }
  }
`;

const COUNT_CHAT = gql`query countChat($search: String!, $nps: String!, $closed: String!){
  chats_count(search:$search, nps:$nps, closed:$closed){
    count
  }
}
`;


class ChatList extends Component {

  state = {
    search: "",
    limit: 20,
    page: 1,
    orderby: "datePublish",
    order: "DESC",
    nps: "",
    closed: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value,
      nps: event.target.elements.nps.value,
      closed: event.target.elements.closed.value,
    });
    

  }

  render() {


    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Chamados"
                category="Gerenciar chamados"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                              <Row>
                                <Col md={6} lg={3}>
                                      <select name="nps" className="form-control">
                                        <option value="">Todos NPS</option>
                                        <option value="1" selected={this.state.nps == "1"}>Bom</option>
                                        <option value="2" selected={this.state.nps == "2"}>Médio</option>
                                        <option value="3" selected={this.state.nps == "3"}>Ruim</option>
                                      </select>
                                  </Col>
                                <Col md={6} lg={2}>
                                      <select name="closed" className="form-control">
                                        <option value="">- Status/Chamados -</option>
                                        <option value="3" selected={this.state.closed == "3"}>Chamados Abertos</option>
                                        <option value="0" selected={this.state.closed == "0"}>Conversas em andamento</option>
                                        <option value="1" selected={this.state.closed == "1"}>Conversas finalizadas</option>
                                      </select>
                                  </Col>
                              </Row>
                              <Row>
                                
                                  <Col md={6} lg={4}>
                                      <input  type="text" name="search" className="form-control" />
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} lg={2}>
                                      <select name="orderby" className="form-control">
                                        <option value="datePublish">Data</option>
                                        <option value="name" selected={this.state.orderby == "name"}>Nome</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <td colspan="11">
                        <a className="btn btn-info" href="/api/v1/chat/chat_reports" rel='noreferrer noopener'>
                          Download
                        </a>
                        </td>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Chamado</th>
                        <th>Data/Hora</th>
                        <th>Tipo</th>
                        <th>Conteúdo</th>
                        <th>Categoria</th>
                        <th>Status</th>
                        <th>Gerou chamado</th>
                        <th>NPS</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query 
                          client={chat_client}
                          fetchPolicy='no-cache'
                          variables={{
                            search: this.state.search, 
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby,
                            nps: this.state.nps,
                            closed: this.state.closed
                          }}
                          query={gql`query chats_collections($search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!, $nps: String!, $closed: String!){
                              chats_collections(search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby, nps:$nps, closed:$closed){
                                chats{
                                  id
                                  title
                                  date_publish_format_br
                                  closed
                                  is_helpdesk
                                  nps
                                  chat_category{
                                    title
                                  }
                                  chat_type{
                                    title
                                  }
                                  chat_type_content{
                                    title
                                  }
                                }
                              }
                            }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                            return data.chats_collections.chats.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.date_publish_format_br}</td>
                                <td>{item.chat_type ? item.chat_type.title : ""}</td>
                                <td>{item.chat_type_content ? item.chat_type_content.title : ""}</td>
                                <td>{item.chat_category ? item.chat_category.title : ""}</td>
                                <td>{item.closed ? "Fechado" : "Aberto"}</td>
                                <td>{item.is_helpdesk ? "Sim" : "Não"}</td>
                                <td>{item.nps == 1 ? <img src={NpsImg1} height={40} /> : ( item.nps == 2 ? <img src={NpsImg2} height={40} /> : (( item.nps == 3 ? <img src={NpsImg3} height={40} /> :"")))}</td>
                                <td>
                                  <p><a className="btn btn-primary" href={"/api/v1/chat/report_messages/?chat_id="+item.id}>Download Conversas</a></p>
                                </td>
                                <td>
                                  <p><a className="btn btn-primary" href={getUrl('moderate',item.id)}>Abrir covnersa</a></p>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                      confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            chat_client.mutate({
                                              mutation: DELETE_CHAT,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });

                                            
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                    <Query
                          client={chat_client}
                          query={COUNT_CHAT}
                          variables={{
                            search: this.state.search,
                            nps: this.state.nps,
                            closed: this.state.closed
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.chats_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ChatList;
