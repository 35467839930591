import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import DateTimePicker from 'react-datetime-picker';


const restLink_proof = new HttpLink({ 
  uri: "/api/v1/lms/"
 });
  const lms_client = new ApolloClient({
  link: restLink_proof,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateProof($id: Int!, $title: String!, $prooftype_id: Int! $active: Int!, $date_publish: String!, $date_unpublish: String!, $minimum: Int!){
    createOrUpdateProof(id:$id, input:{title:$title, prooftype_id:$prooftype_id, active:$active, date_publish:$date_publish, date_unpublish:$date_unpublish, minimum:$minimum}){
      id
    }
  }
`;

const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id:$proof_id){
      title,  
      active,
      minimum,
      date_publish_format,
      date_unpublish_format
    }
  }
`;


class ProofItem extends Component {

  proof_id;
  proof_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      checkboxValue_active: false,
      datePublish: new Date(),
      dateUnpublish: null
    };


    /* 
    As variáveis abaixo, começam com start, que para definir o valor 
    Inicial dos campos, e elas existem para definir esse valor inicial 
    apenas quando a página carrega, pois depois são alteradas para "false" 
    */
    this.start_publish_date = true;
    this.start_unpublish_date = true;

    
    this.start_active = true;
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }
  
  render() {

    
    this.proof_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.parent_id = (this.props.match.params.parent_id ? this.props.match.params.parent_id : 0);

    return (


      <Query
      client={lms_client}
      query={GET_PROOF}
      variables={{proof_id:this.proof_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.proof_edit = data.proof;


          if(this.start_active && this.proof_edit && this.proof_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.proof_edit.active === 1 ? true : false)
            })
          }

          if(this.start_publish_date && this.proof_edit && this.proof_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.proof_edit.date_publish_format)
            })
          }
          if(this.start_unpublish_date && this.proof_edit && this.proof_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.proof_edit.date_unpublish_format)
            })
          }

          

        return (

      <Mutation mutation={ADD_TODO} client={lms_client}>
      {(createOrUpdateProof, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to={"/Proof/"+this.props.match.params.type_id}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            

              <Card
                title="Avaliação"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateProof({variables: { 
                      id: this.proof_id, 
                      prooftype_id: parseInt(this.props.match.params.type_id),
                      title: e.target.elements.title.value,
                      minimum: e.target.elements.minimum.value,
                      active: (e.target.elements.active.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Nome da turma",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome da turma",
                        defaultValue: (this.proof_edit ? this.proof_edit.title : "")
                      }
                    ]}
                  />

                    <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "minimum",
                        label: "Mínimo de acertos",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Mínimo de acertos",
                        defaultValue: (this.proof_edit ? this.proof_edit.minimum : "")
                      }
                    ]}
                  />

                <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default ProofItem;
