import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import dashboardRoutes from "../../routes/dashboard.jsx";

class Widget extends Component {
  render() {
    return (
          <Switch>
            {dashboardRoutes.map((prop, key) => {
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
    );
  }
}

export default Widget;
