import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

const restLink_cadastro = new HttpLink({ 
  uri: "/api/v1/corretor/",
  credentials: 'include'
 });
const corretor_client = new ApolloClient({
  link: restLink_cadastro,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateCadastro($id: Int!, $name: String!, $email: String!, $cpf: String!, $susep: String!){
    createOrUpdateCadastro(id:$id, input:{name:$name, email:$email, cpf:$cpf, susep:$susep}){
      id
    }
  }
`;

const GET_CADASTRO = gql`
  query getCadastro($cadastro_id: Int!){
    cadastro(id:$cadastro_id){
      name, email, cpf, susep
    }
  }`;



class CadastroItem extends Component {

  cadastro_id;
  cadastro_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: ""
    };
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {


    return (


      <Query
      client={corretor_client}
      query={GET_CADASTRO}
      variables={{cadastro_id:this.props.match.params.id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.cadastro_edit = data.cadastro;

        return (

      <Mutation mutation={ADD_TODO} client={corretor_client}>
      {(createOrUpdateCadastro, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Corretor"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                name="Usuário"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();

                    createOrUpdateCadastro({variables: { 
                      id: this.props.match.params.id, 
                      name: e.target.elements.name.value,
                      email: e.target.elements.email.value,
                      cpf: e.target.elements.cpf.value,
                      susep: e.target.elements.susep.value
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "name",
                        label: "Nome",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Nome",
                        defaultValue: (this.cadastro_edit ? this.cadastro_edit.name : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "email",
                        label: "E-mail",
                        type: "email",
                        bsClass: "form-control",
                        placeholder: "E-mail",
                        defaultValue: (this.cadastro_edit ? this.cadastro_edit.email : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "cpf",
                        label: "CPF",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "CPF",
                        defaultValue: (this.cadastro_edit ? this.cadastro_edit.cpf : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "susep",
                        label: "Susep",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Susep",
                        defaultValue: (this.cadastro_edit ? this.cadastro_edit.susep : "")
                      }
                    ]}
                  />
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default CadastroItem;
