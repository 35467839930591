import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import { convertToRaw, convertFromRaw, EditorState, ContentState, convertFromHTML  } from 'draft-js';

//import ReactMarkdown from 'react-markdown';
//import htmlParser from 'react-markdown/plugins/html-parser';


const restLink_Communication = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const Communication_client = new ApolloClient({
  link: restLink_Communication,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}




const GET_USER = gql`
query getUser($id: Int!){
  user(id:$id){
    id
    name
    uid
    mail
    userMetas{
      account_field{
        field
      }
      account_field_value{
        value
      }
    }
  }
}
`;


class CommunicationHasUserList extends Component {


  render() {

    //const parseHtml = htmlParser({
    //  isValidNode: node => node.type !== 'script',
    //  processingInstructions: [/* ... */]
    //})

    function convertHtmlToElement(html) {
      var blocksFromHTML = convertFromHTML(html)
      var content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return content;
    }

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to='/Communication-new'
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Nova Comunicação
              </NavLink>
              <Card
                title="Conversas"
                category="Gerenciar Conversas"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Visualização</th>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>CPF/Matricula</th>
                        <th>Hierarquia</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={Communication_client}
                          fetchPolicy='no-cache'
                          variables={{communication_id: this.props.match.params.id}}
                          query={gql`
                          query communication_has_user($communication_id: Int!){
                            communication_has_user(communication_id:$communication_id) {
                              communication_has_user{
                                id
                                user_id
                                timestamp_format_br
                              }
                            }
                          }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              
                            return data.communication_has_user.communication_has_user.map((item) => (
                              
                              <Query
                                client={account_client}
                                variables={{id: item.user_id}}
                                query={GET_USER}
                              >
                                {({ loading, error, data }) => {
                                  if (loading) return <tr><td>Carregando...</td></tr>;
                                  if (error) return <tr><td>Erro :(</td></tr>;

                                    if(!data.user) {
                                      return <div></div>
                                    }
                                    return (
                                      <tr key={item.id}>
                                        <td>{item.timestamp_format_br}</td>
                                        <td>{data.user.name}</td>
                                        <td>{data.user.mail}</td>
                                        <td>{data.user.uid}</td>
                                        <td>{data.user.userMetas.map((item) => (
                                          <div>{item.account_field.field}: {item.account_field_value.value}</div>
                                        ))}</td>
                                      </tr>
                                      )
                                    }
                                }
                                </Query>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CommunicationHasUserList;
