import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import DateTimePicker from 'react-datetime-picker';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

import { Editor } from 'react-draft-wysiwyg';
import createImagePlugin from 'draft-js-image-plugin';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js-image-plugin/lib/plugin.css';
import { convertToRaw, convertFromRaw, EditorState, ContentState, convertFromHTML, CompositeDecorator, Entity  } from 'draft-js';
import Parser from 'html-react-parser';


//import {draftToMarkdown, markdownToDraft} from 'draftjs-to-markdown';

import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';

import draftToHtml from 'draftjs-to-html';
import {stateFromHTML} from 'draft-js-import-html';


const restLink_communication = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const communication_client = new ApolloClient({
  link: restLink_communication,
  cache: new InMemoryCache(),
});


const ADD_TODO = gql`
  mutation createOrUpdateCommunication($id: Int!, $title: String!, $message: String!, $iframe: String!,  $datetime_start: String!,  $datetime_end: String!, $active: Int!){
    createOrUpdateCommunication(id:$id, input:{title:$title, message:$message, iframe:$iframe, datetime_start:$datetime_start, datetime_end:$datetime_end, active:$active}){
      id
    }
  }
`;

const GET_CHAT = gql`
  query getCha($communication_id: Int!){
    communication(id:$communication_id){
      id
      title
      message
      iframe
      datetime_start_format
      datetime_end_format
      active
    }
  }
`;


 

class CommunicationItem extends Component {

  communication_id;
  communication_edit;

  state = {
    alertInfo: "",
    checkboxValue_active: false,
    checkboxValue_canceled: false,
    checkboxValue_moderation: false,
    datePublish: new Date(),
    dateUnpublish: null,
    editorState: undefined
  };

  constructor() {
    super();
    this.start_publish_date = true;
    this.start_unpublish_date = true;

    this.start_datetime_start = true;
    this.start_datetime_end = true;
    
    this.start_active = true;

    this.start_message = true;
    
    
  }

  

  handleSubmit = (event) => {
    event.preventDefault();
    

    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  
  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  onChange_datetimeStart = datetimeStart => this.setState({ datetimeStart })
  onChange_datetimeEnd = datetimeEnd => this.setState({ datetimeEnd })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_canceled = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_canceled: !this.state.checkboxValue_canceled
    }));

  }

  handleCheckboxChange_moderation = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_moderation: !this.state.checkboxValue_moderation
    }));

  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  uploadCallback(file) {
    console.log(file);
  }
  
  render() {

    const configEditor={
      image: { uploadCallback: this.uploadCallback }
    }
    
    if(this.props.match.params.id) {
      this.communication_id = this.props.match.params.id;
    } else {
      this.communication_id = 0;
    }
    const decorators = [
      { strategy: findLinkEntities, component: Link }
    ]

    return (

      <Query
      client={communication_client}
      fetchPolicy='no-cache'
      query={GET_CHAT}
      variables={{communication_id:this.communication_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.communication_edit = data.communication;


          if(this.start_active && this.communication_edit && this.communication_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.communication_edit.active === 1 ? true : false)
            })
          }

          if(this.start_datetime_start && this.communication_edit && this.communication_edit.datetime_start_format) {
            this.start_datetime_start = false;
            this.setState({
              datetimeStart: new Date(this.communication_edit.datetime_start_format)
            })
          }
          if(this.start_datetime_end && this.communication_edit && this.communication_edit.datetime_end_format) {
            this.start_datetime_end = false;
            this.setState({
              datetimeEnd: new Date(this.communication_edit.datetime_end_format)
            })
          }

          if(this.communication_edit && this.start_message) {
            this.start_message = false;

            const content = convertFromRaw(markdownToDraft(this.communication_edit.message));
            const editorState = EditorState.createWithContent(content);

            this.setState({
              editorState: editorState
            })

            
          }


        return (

      <Mutation mutation={ADD_TODO} client={communication_client}>
      {(createOrUpdateCommunication, { data }) => (
      <div className="content">
        
        <Grid fluid>
          <Row>
            <Col md={12}>

            <a
                href='javascript:history.go(-1)'
                className="btn btn-info btn-fill"
              >
              VOLTAR
            </a>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Comunicação Geral"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateCommunication({variables: { 
                      id: (data && data.createOrUpdateCommunication && data.createOrUpdateCommunication.id ? data.createOrUpdateCommunication.id : this.communication_id), // Verifica se existe o ID de retorno da inserção, no cso de criar um novo item ele pega o ID do item que acabou de ser inserido
                      title: e.target.elements.title.value, 
                      message: e.target.elements.message.value, 
                      iframe: e.target.elements.iframe.value, 
                      active: (e.target.elements.active.checked ? 1 : 0),
                      datetime_start: e.target.elements.datetime_start.value,
                      datetime_end: e.target.elements.datetime_end.value
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.communication_edit ? this.communication_edit.title : "")
                      }
                    ]}
                  />

                  <div className="form-group">
                    <label className="control-label">Mensagem</label>
                    <Editor
                      decorators={decorators}
                      toolbar={ configEditor }
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <textarea
                      disabled
                      hidden={true}
                      name="message"
                      value={this.state.editorState && draftToMarkdown(convertToRaw(this.state.editorState.getCurrentContent()))}
                    />
                  </div>


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "iframe",
                        label: "URL do Embed",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "URL do Embed",
                        defaultValue: (this.communication_edit ? this.communication_edit.iframe : "")
                      }
                    ]}
                  />

                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Data e Horá de início</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_start"
                        onChange={this.onChange_datetimeStart}
                        value={this.state.datetimeStart}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data e Hora de fim</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_end"
                        onChange={this.onChange_datetimeEnd}
                        value={this.state.datetimeEnd}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>
                  
                 
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

              
              
            </Col>

            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

function findLinkEntities(contentBlock, callback) {
  contentBlock.findEntityRanges(
    (character) => {
      console.log("fffffffffff");
      console.log(contentBlock);
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        Entity.get(entityKey).getType() === 'LINK'
      )
    },
    callback
  )
}

const Link = (props) => {
  const {url} = Entity.get(props.entityKey).getData();
  return (
    <a href={url}>{props.children} aaaaa</a>
  )
}

export default CommunicationItem;
