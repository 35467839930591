import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import DateTimePicker from 'react-datetime-picker';

const restLink_chat = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const chat_client = new ApolloClient({
  link: restLink_chat,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}



class Calendar extends Component {

  constructor() {
    super();
    this.state = {
      dateCalendar: new Date()
    };
  
  }

  onChange_calendar = dateCalendar => this.setState({ dateCalendar })

  minutes_with_leading_zeros(dt) 
  { 
    return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
  }

  render() {


    console.log(this.state.dateCalendar.getFullYear());
    console.log(this.state.dateCalendar.getMonth());

    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to='/Chat-new'
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Nova Sala
              </NavLink>
              <NavLink
                  to='/Chat'
                  className="btn btn-success btn-fill"
                  activeClassName=""
                >
                Lista
              </NavLink>
              <Card
                title="Sala Virtual"
                category="Gerenciar sala virtual"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <div>
                    <Col md={6}>
                      <div className="header">
                        <DateTimePicker
                          isClearable={false}
                          name="date"
                          onChange={this.onChange_calendar}
                          value={this.state.dateCalendar}
                          required={false}
                          format='d/M/y H:m'
                          lang= 'pt'
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="header">
                        <a className="btn btn-info btn-fill pull-right" href={"/api/v1/chat/report_status?year="+this.state.dateCalendar.getFullYear()+"&month="+ (parseInt(this.state.dateCalendar.getMonth())+1)}>
                          Baixar todas salas deste mês
                        </a>
                        <a className="btn btn-info btn-fill pull-right" href={"/api/v1/analytics/get_users_by_chat?year="+this.state.dateCalendar.getFullYear()+"&month="+ (parseInt(this.state.dateCalendar.getMonth())+1)}>
                          Baixar todos acessos mês
                        </a>
                      </div>
                    </Col>
                    <div className="clear"></div>
                    <div className="calendar">
                      <div className="clear"></div>
                      <div className="calendar_header">
                        <div className="day_week_in_calendar">Domingo</div>
                        <div className="day_week_in_calendar">Segunda</div>
                        <div className="day_week_in_calendar">Terça</div>
                        <div className="day_week_in_calendar">Quarta</div>
                        <div className="day_week_in_calendar">Quinta</div>
                        <div className="day_week_in_calendar">Sexta</div>
                        <div className="day_week_in_calendar">Sábado</div>
                        <div className="clear"></div>
                      </div>

                        <Query
                          client={chat_client}
                          fetchPolicy='no-cache'
                          variables={{date: this.state.dateCalendar}}
                          query={gql`
                          query getCalendar($date: String!){
                            chats_calendar(date:$date){
                              chats{
                                id,
                                title,
                                scope,
                                level,
                                area,
                                room,
                                datetime_start_format,
                                datetime_end_format,
                                datetime_start_format_br,
                                datetime_end_format_br
                              }
                            }
                          }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              
                            var items_days = [];
                            data.chats_calendar.chats.forEach((item) => {
                              var date = new Date(item.datetime_start_format).getDate();
                              if(!items_days[date]) {
                                items_days[date] = [];
                              }
                              items_days[date].push(item);
                            });

                            var month = new Date(this.state.dateCalendar).getMonth();
                            var year = new Date(this.state.dateCalendar).getFullYear();
                            var date = new Date(year, month, 1);
                            var days_month = [];

                            var first_day = true;
                            while (date.getMonth() === month) {
                              if(first_day) {
                                first_day = false;
                                var week_day = date.getDay();
                                while(week_day) {
                                  days_month.push(false);
                                  week_day--;
                                }
                              }
                               days_month.push(new Date(date));
                               date.setDate(date.getDate() + 1);
                            }

                            return days_month.map((day) => (
                              
                              <div className="day_week_in_calendar">
                                <div className={day && day.toLocaleDateString("pt-BR") === new Date(this.state.dateCalendar).toLocaleDateString("pt-BR") ? "current" : ""}>
                                <div className="number_day">{day ? day.getDate() : "-"}</div>
                                {
                                day && items_days[day.getDate()] ?
                                  items_days[day.getDate()].map((item) => {
                                    var title = new Date(item.datetime_start_format).getHours() + ":" + this.minutes_with_leading_zeros(new Date(item.datetime_start_format)) + " - " + item.title
                                    var titleHover = title + ' - Início: ' +item.datetime_start_format_br + ' - Fim: ' +item.datetime_end_format_br + ' - Local: ' +item.room + ' - Abrangência: ' +item.scope + ' - Nível: ' +item.level;
                                    
                                  return (
                                    <div className="item_calendar">
                                    <a href={getUrl('Chat-edit',item.id)} title={titleHover}>
                                      {title}
                                    </a>
                                    </div>
                                  )
                                })
                                : ""}
                                </div>
                              </div>
                            ));
                          }}
                        </Query>
                        <div className="clear"></div>
                  </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Calendar;
