import Dashboard from "../layouts/Dashboard/Dashboard.jsx";
import Chat_Widget from "../layouts/Chat/Widget.jsx";
import LoginTemplate from "../layouts/Login/Login.jsx";



try {
    if(window.self !== window.top){
        var layout = Chat_Widget;
    } else {
        var layout = Dashboard;
    }
} catch (e) {
    const auth_uri = "#/pluzbot/";
    if(window.location.hash.indexOf(auth_uri) === 0){
        var layout = Chat_Widget;
    } else {
        var layout = Dashboard;
    }
}

if(window.location.hash.indexOf("#/login") === 0){// Verifica se está na página de auth
    var layout = LoginTemplate;
}

var indexRoutes = [
    { path: "/home", name: "Início", component: layout }
];

export default indexRoutes;
