import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import 'react-confirm-alert/src/react-confirm-alert.css'; //
import Button from "../../components/CustomButton/CustomButton.jsx";


const restLink_cadastro = new HttpLink({ credentials: 'include', uri: "/api/v1/corretor/" });
const cadastro_client = new ApolloClient({
  link: restLink_cadastro,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_CADASTROS = gql`query getCadastros($search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
    cadastros_collections(search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby){
      cadastros{
        id, name, cpf, susep, email
      }
    }
  }
`;

const COUNT_CADASTROS = gql`query countCadastros($search: String!){
  cadastros_count(search:$search){
    count
  }
}

`;

// const DELETE_CADASTRO = gql`
//   mutation deleteCadastro($id: Int!){
//     deleteCadastro(id:$id) {
//       id
//     }
//   }
// `;


class CadastroList extends Component {

  state = {
    search: "",
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });
    

  }
  
  render() {
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                name={"Usuários"}
                cadastro="Gerenciar usuários"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                              <Row>
                                  <Col md={6} lg={3}>
                                      <input  type="text" name="search" className="form-control" />
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} lg={1}>
                                      <select name="orderby" className="form-control">
                                        <option value="id">ID</option>
                                        <option value="name" selected={this.state.orderby == "name"}>Nome</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>CPF</th>
                        <th>Susep</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={cadastro_client}
                          query={GET_CADASTROS}
                          variables={{
                            search: this.state.search, 
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.cadastros_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.cadastros_collections.cadastros.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.cpf}</td>
                                <td>{item.susep}</td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('Corretor-edit',item.id)}>Editar</a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        <Query
                          client={cadastro_client}
                          query={COUNT_CADASTROS}
                          variables={{
                            search: this.state.search
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.cadastros_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CadastroList;
