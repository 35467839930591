import React, { Component } from "react";

import {
  Grid,
  Row,
  Col,
  Alert,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import logo from "../../assets/img/logo.png";


const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const LOGIN = gql`
  query login($email: String!, $password: String!){
    login(email:$email, password:$password){
      id
      token
    }
  }
`;

class Login extends Component {
  
  state = {
    email: null,
    password: null,
    msg: null,
    msg_type: "info"
  }


  login = async () => {
    const user = (await account_client.query({
      variables: {email: this.state.email, password:this.state.password},
      query: LOGIN,
      fetchPolicy: 'no-cache'
    })).data.login;
    if(user.token !== null) {
      document.cookie = "token_user="+user.token;// Grava o coookie
      this.setState({
        msg_type: "info",
        msg: "Login feito com sucesso, aguarde, você será redirecionado."
      });
      window.location.replace('/');
    } else {
      this.setState({
        msg_type: "warning",
        msg: "Verifique seu e-mail e senha, e tente novamente."
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.login();
  }

  //onChange_email = email => this.setState({ email })

  onChange_email = (event) => {
    this.setState({email: event.target.value});
  }
  onChange_password = (event) => {
      this.setState({password: event.target.value});
  }
  

  render() {

    return (
      <div className="login-box">
        <img className="login-logo" src={logo} height="60px" />
              <form 
              onSubmit={e => {
                this.handleSubmit(e);
                e.preventDefault();
              }}
              >
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        onChange:this.onChange_email,
                        name: "email",
                        label: "E-mail",
                        type: "email",
                        bsClass: "form-control",
                        placeholder: "E-mail",
                        defaultValue: ""
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        onChange:this.onChange_password,
                        name: "password",
                        label: "Senha",
                        type: "password",
                        bsClass: "form-control",
                        placeholder: "Senha",
                        defaultValue: ""
                      }
                    ]}
                  />

                  <Row>
                    <Col md={12}>
                        <Button bsStyle="info" fill type="submit">
                          ENTRAR
                        </Button>
                    </Col>
                    {
                      this.state.msg &&
                      <Col md={10}>
                        <Alert bsStyle={this.state.msg_type}>
                          {this.state.msg}
                        </Alert>
                      </Col>
                    }
                    
                  </Row>

             </form>
      </div>
    );
  }
}

export default Login;
