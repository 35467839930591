import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import DateTimePicker from 'react-datetime-picker';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

import { Editor } from 'react-draft-wysiwyg';
import createImagePlugin from 'draft-js-image-plugin';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js-image-plugin/lib/plugin.css';
import { convertToRaw, convertFromRaw, EditorState, ContentState, convertFromHTML, CompositeDecorator, Entity  } from 'draft-js';
import Parser from 'html-react-parser';


//import {draftToMarkdown, markdownToDraft} from 'draftjs-to-markdown';

import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';

import draftToHtml from 'draftjs-to-html';
import {stateFromHTML} from 'draft-js-import-html';


const restLink_conversation = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const conversation_client = new ApolloClient({
  link: restLink_conversation,
  cache: new InMemoryCache(),
});

const restLink_lms = new HttpLink({ credentials: 'include', uri: "/api/v1/lms/" });
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});


const ADD_TODO = gql`
  mutation createOrUpdateConversation($id: Int!, $parent_id: Int!, $title: String!, $iframe: String!,  $question: String!,  $answer: String!, $type: String!, $chat_type_id: Int!, $chat_type_content_id: Int!, $chat_category_id: Int!){
    createOrUpdateConversation(id:$id, input:{parent_id:$parent_id, title:$title, iframe:$iframe, question:$question, answer:$answer, type:$type, chat_type_id:$chat_type_id, chat_type_content_id:$chat_type_content_id, chat_category_id:$chat_category_id}){
      id
    }
  }
`;

const GET_CHAT = gql`
  query getCha($conversation_id: Int!){
    conversation(id:$conversation_id){
      id,
      title,
      iframe,
      question
      answer,
      type,
      chat_category{
        value:id, label:title
      },
      chat_type{
        value:id, label:title
      },
      chat_type_content{
        value:id, label:title
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
    }
  }
`;


 

class ConversationItem extends Component {

  conversation_id;
  conversation_edit;

  state = {
    alertInfo: "",
    checkboxValue_active: false,
    checkboxValue_canceled: false,
    checkboxValue_moderation: false,
    datePublish: new Date(),
    dateUnpublish: null,
    editorState: undefined
  };

  constructor() {
    super();
    this.start_publish_date = true;
    this.start_unpublish_date = true;

    this.start_datetime_start = true;
    this.start_datetime_end = true;
    
    this.start_active = true;
    this.start_canceled = true;
    this.start_moderation = true;

    this.start_answer = true;
    
    
  }

  

  handleSubmit = (event) => {
    event.preventDefault();
    

    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  
  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  onChange_datetimeStart = datetimeStart => this.setState({ datetimeStart })
  onChange_datetimeEnd = datetimeEnd => this.setState({ datetimeEnd })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_canceled = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_canceled: !this.state.checkboxValue_canceled
    }));

  }

  handleCheckboxChange_moderation = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_moderation: !this.state.checkboxValue_moderation
    }));

  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  uploadCallback(file) {
    console.log(file);
  }
  
  render() {


    if(this.props.match.params.parent_id) {
      this.parent_id = this.props.match.params.parent_id;
    } else {
      this.parent_id = 0;
    }

    const configEditor={
      image: { uploadCallback: this.uploadCallback }
    }
    
    if(this.props.match.params.id) {
      this.conversation_id = this.props.match.params.id;
    } else {
      this.conversation_id = 0;
    }
    const decorators = [
      { strategy: findLinkEntities, component: Link }
    ]

    return (

      <Query
      client={conversation_client}
      fetchPolicy='no-cache'
      query={GET_CHAT}
      variables={{conversation_id:this.conversation_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.conversation_edit = data.conversation;


          if(this.conversation_edit && this.start_answer) {
            this.start_answer = false;
            
            //const blocksFromHTML = convertFromHTML(this.conversation_edit.answer);
            //const content = ContentState.createFromBlockArray(
            //  blocksFromHTML
            //);
            //const content = stateFromHTML(this.conversation_edit.answer);
            //const editorState = EditorState.createWithContent(content, new CompositeDecorator(decorators));

            const content = convertFromRaw(markdownToDraft(this.conversation_edit.answer));
            const editorState = EditorState.createWithContent(content);

            this.setState({
              editorState: editorState
            })

            
          }


        return (

      <Mutation mutation={ADD_TODO} client={conversation_client}>
      {(createOrUpdateConversation, { data }) => (
      <div className="content">
        
        <Grid fluid>
          <Row>
            <Col md={12}>

            <a
                href='javascript:history.go(-1)'
                className="btn btn-info btn-fill"
              >
              VOLTAR
            </a>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="ConversationBot"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateConversation({variables: { 
                      id: (data && data.createOrUpdateConversation && data.createOrUpdateConversation.id ? data.createOrUpdateConversation.id : this.conversation_id), // Verifica se existe o ID de retorno da inserção, no cso de criar um novo item ele pega o ID do item que acabou de ser inserido
                      title: e.target.elements.title.value, 
                      question: e.target.elements.question.value, 
                      answer: e.target.elements.answer.value, //draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
                      iframe: e.target.elements.iframe.value,  
                      type: e.target.elements.type.value, 
                      chat_type_id: (e.target.elements.chat_type.value ? parseInt(e.target.elements.chat_type.value) : 0), 
                      chat_type_content_id: (e.target.elements.chat_type_content.value ? parseInt(e.target.elements.chat_type_content.value) : 0), 
                      chat_category_id: (e.target.elements.chat_category.value ? parseInt(e.target.elements.chat_category.value) : 0),
                      parent_id: this.parent_id
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.conversation_edit ? this.conversation_edit.title : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "question",
                        label: "Possibildiades de perguntas",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Possibildiades de perguntas",
                        defaultValue: (this.conversation_edit ? this.conversation_edit.question : "")
                      }
                    ]}
                  />
                  
                  <div className="form-group">
                    <label className="control-label">Resposta</label>
                    <Editor
                      decorators={decorators}
                      toolbar={ configEditor }
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <textarea
                      disabled
                      hidden={true}
                      name="answer"
                      value={this.state.editorState && draftToMarkdown(convertToRaw(this.state.editorState.getCurrentContent()))}
                    />
                  </div>

                  
                  <div className="form-group">
                    <label className="control-label">Tipo de resposta</label>
                    <Select
                    name="type"
                    options={[{'label':'Resposta', 'value':'Resposta'},  {'label':'Cumprimento', 'value':'Cumprimento'},  {'label':'Despedida', 'value':'Despedida'}]}
                    className="basic-select"
                    classNamePrefix="select"
                    isClearable={true}
                    defaultValue={this.conversation_edit ? {'label':this.conversation_edit.type, 'value':this.conversation_edit.type} : ""}
                  />
                  </div>
                  
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "iframe",
                        label: "URL do Embed",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "URL do Embed",
                        defaultValue: (this.conversation_edit ? this.conversation_edit.iframe : "")
                      }
                    ]}
                  />

                    <FormGroup>
                      <ControlLabel>Tipo de conversa</ControlLabel>
                      <div>
                        <Query
                            client={conversation_client}
                            query={gql`{
                              chat_type_collections{
                                chat_type{
                                  value:id, label:title
                                }
                              }
                            }`}
                            >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error) return <div>Erro :(</div>;
                              return (
                                <Select
                                  name="chat_type"
                                  options={data.chat_type_collections ? data.chat_type_collections.chat_type : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.conversation_edit && this.conversation_edit ? this.conversation_edit.chat_type : ""}
                                />
                                );
                              }}
                        </Query>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Tipo de conteúdo</ControlLabel>
                      <div>
                        <Query
                            client={conversation_client}
                            query={gql`{
                              chat_type_content_collections{
                                chat_type_content{
                                  value:id, label:title
                                }
                              }
                            }`}
                            >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error) return <div>Erro :(</div>;
                              return (
                                <Select
                                  name="chat_type_content"
                                  options={data.chat_type_content_collections ? data.chat_type_content_collections.chat_type_content : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.conversation_edit && this.conversation_edit ? this.conversation_edit.chat_type_content : ""}
                                />
                                );
                              }}
                        </Query>
                      </div>
                    </FormGroup>


                    <FormGroup>
                      <ControlLabel>Categoria</ControlLabel>
                      <div>
                        <Query
                            client={conversation_client}
                            query={gql`{
                              chat_category_collections{
                                chat_category{
                                  value:id, label:title
                                }
                              }
                            }`}
                            >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error) return <div>Erro :(</div>;
                              return (
                                <Select
                                  name="chat_category"
                                  options={data.chat_category_collections ? data.chat_category_collections.chat_category : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.conversation_edit && this.conversation_edit ? this.conversation_edit.chat_category : ""}
                                />
                                );
                              }}
                        </Query>
                      </div>
                    </FormGroup>
                  
                 
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

              
              
            </Col>

            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

function findLinkEntities(contentBlock, callback) {
  contentBlock.findEntityRanges(
    (character) => {
      console.log("fffffffffff");
      console.log(contentBlock);
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        Entity.get(entityKey).getType() === 'LINK'
      )
    },
    callback
  )
}

const Link = (props) => {
  const {url} = Entity.get(props.entityKey).getData();
  return (
    <a href={url}>{props.children} aaaaa</a>
  )
}

export default ConversationItem;
