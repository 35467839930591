import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";



import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import DateTimePicker from 'react-datetime-picker';




const restLink_analytics = new HttpLink({ 
  uri: "/api/v1/analytics/",
  credentials: 'include'
});
const analytics_client = new ApolloClient({
  link: restLink_analytics,
  cache: new InMemoryCache(),
});

const restLink_chat = new HttpLink({ 
  uri: "/api/v1/chat/",
  credentials: 'include'
});
const chat_client = new ApolloClient({
  link: restLink_chat,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});



const INTERACTIONS = gql`
query getInteractions($interval: String!){
  report_interactions(interval: $interval){
    chats_count
    messages_count
    total_time
    average_time
  }
}
`;


const LIST_CHAT_TYPES = gql`
query getChatTypeCount($interval: String!){
  report_type_count(interval: $interval){
    items{
      title
      count
    }
  }
}

`;
const LIST_CHAT_TYPE_CONTENTS = gql`
query getChatTypeContentCount($interval: String!){
  report_type_content_count(interval: $interval){
    items{
      title
      count
    }
  }
}

`;


const COUNT_USER = gql`
query getCountUser($interval: String!){
  userCount(interval: $interval) {
    count
  }
}
`;


class Dashboard extends Component {

  state = {
    graph_page_views:{
      dates_page_views:{
        labels: [],
        series: [
          []
        ]
      }
    }
  };

  onChange_calendar_start = dateCalendar_start => this.setState({ dateCalendar_start })
  onChange_calendar_end = dateCalendar_end => this.setState({ dateCalendar_end })


  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  componentDidMount() {
    this.getReports();
  }



  getReports = async () => {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var labels = [];
    var series = [];
    var max = 0;
   

  }


  render() {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var today_string = yyyy + '-' + mm + '-' + dd ;
    var yesterday_string = yyyy + '-' + mm + '-' + (dd-1) ;




    return (
      <div className="content">
        <Grid fluid>
        <Row>
          <Col lg={12}>
            <h3>Chatbot - Mês atual</h3>
          </Col>
        </Row>
        <Query
              client={chat_client}
              query={INTERACTIONS}
              variables={{interval: "month"}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  return (
                    <Row>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Total de conversas"
                          statsValue={data.report_interactions.chats_count}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Mensagens recebidas"
                          statsValue={data.report_interactions.messages_count}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Tempo total de todas conversas"
                          statsValue={data.report_interactions.total_time}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Tempo médio por conversa"
                          statsValue={data.report_interactions.average_time}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                    </Row>
              );
            }
          }
          </Query>

          <Row>
          <Col lg={12}>
            <h3>Chatbot - Todo o histórico</h3>
          </Col>
        </Row>
        <Query
              client={chat_client}
              query={INTERACTIONS}
              variables={{interval: "all"}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  return (
                    <Row>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Total de conversas"
                          statsValue={data.report_interactions.chats_count}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Mensagens recebidas"
                          statsValue={data.report_interactions.messages_count}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Tempo total de todas conversas"
                          statsValue={data.report_interactions.total_time}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                      <Col lg={3} sm={3}>
                        <StatsCard
                          bigIcon={<i className="pe-7s-chat text-info" />}
                          statsText="Tempo médio por conversa"
                          statsValue={data.report_interactions.average_time}
                          statsIcon={<i className="fa fa-refresh" />}
                          statsIconText="Atualizado agora"
                        />
                      </Col>
                    </Row>
              );
            }
          }
          </Query>




          <Row>
            <Col lg={12}>
              <h3>Chatbot - Tipos de conversas</h3>
            </Col>
          </Row>

          <Row>

          <Col md={6}>
              <Card
                title="Quantidade do mês"
                category=""
                stats="Atualizado"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                    <tbody>
                      <Query
                            client={chat_client}
                            query={LIST_CHAT_TYPES}
                            variables={{interval: "month"}}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <tr><td>Carregando...</td></tr>;
                              if (error) return <tr><td>Erro :(</td></tr>;
                                
                                if(!data.report_type_count.items) {
                                  return (<tr><td>(Vazio)</td></tr>);
                                } 

                                return data.report_type_count.items.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {item.title}
                                  </td>
                                  <td>{item.count}</td>
                                </tr>
                              ));
                          }}
                      </Query>
                    </tbody>
                    </table>
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Quantidade total"
                category=""
                stats="Atualizado"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                    <tbody>
                      <Query
                            client={chat_client}
                            query={LIST_CHAT_TYPES}
                            variables={{interval: "all"}}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <tr><td>Carregando...</td></tr>;
                              if (error) return <tr><td>Erro :(</td></tr>;
                                
                                if(!data.report_type_count.items) {
                                  return (<tr><td>(Vazio)</td></tr>);
                                } 

                                return data.report_type_count.items.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {item.title}
                                  </td>
                                  <td>{item.count}</td>
                                </tr>
                              ));
                          }}
                      </Query>
                    </tbody>
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>



          <Row>
            <Col lg={12}>
              <h3>Chatbot - Tipos de conteúdos</h3>
            </Col>
          </Row>

          <Row>

          <Col md={6}>
              <Card
                title="Quantidade do mês"
                category=""
                stats="Atualizado"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                    <tbody>
                      <Query
                            client={chat_client}
                            query={LIST_CHAT_TYPE_CONTENTS}
                            variables={{interval: "month"}}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <tr><td>Carregando...</td></tr>;
                              if (error) return <tr><td>Erro :(</td></tr>;
                                
                                if(!data.report_type_content_count.items) {
                                  return (<tr><td>(Vazio)</td></tr>);
                                } 

                                return data.report_type_content_count.items.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {item.title}
                                  </td>
                                  <td>{item.count}</td>
                                </tr>
                              ));
                          }}
                      </Query>
                    </tbody>
                    </table>
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Quantidade total"
                category=""
                stats="Atualizado"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                    <tbody>
                      <Query
                            client={chat_client}
                            query={LIST_CHAT_TYPE_CONTENTS}
                            variables={{interval: "all"}}
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <tr><td>Carregando...</td></tr>;
                              if (error) return <tr><td>Erro :(</td></tr>;
                                
                                if(!data.report_type_content_count.items) {
                                  return (<tr><td>(Vazio)</td></tr>);
                                } 

                                return data.report_type_content_count.items.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {item.title}
                                  </td>
                                  <td>{item.count}</td>
                                </tr>
                              ));
                          }}
                      </Query>
                    </tbody>
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>










       
         <Row>
         <Col lg={12}>
            <h3>Usuários</h3>
          </Col>
        </Row>
          <Row>
            <Col lg={4} sm={4}>
            <Query
              client={account_client}
              query={COUNT_USER}
              variables={{interval: 'all'}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  return (
                    
                      <StatsCard
                        bigIcon={<i className="pe-7s-users text-info" />}
                        statsText="Total de usuários"
                        statsValue={data.userCount.count}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Atualizado agora"
                      />
                  );
                }
              }
              </Query>
            </Col>
            <Col lg={4} sm={4}>
            <Query
              client={account_client}
              query={COUNT_USER}
              variables={{interval: 'today'}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  return (
                    
                      <StatsCard
                        bigIcon={<i className="pe-7s-users text-info" />}
                        statsText="Novos Usuários Hoje"
                        statsValue={data.userCount.count}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Atualizado agora"
                      />
                  );
                }
              }
              </Query>
            </Col>
            <Col lg={4} sm={4}>
            <Query
              client={account_client}
              query={COUNT_USER}
              variables={{interval: 'yesterday'}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;

                  return (
                    
                      <StatsCard
                        bigIcon={<i className="pe-7s-users text-info" />}
                        statsText="Novos Usuários Ontem"
                        statsValue={data.userCount.count}
                        statsIcon={<i className="fa fa-refresh" />}
                        statsIconText="Atualizado agora"
                      />
                  );
                }
              }
              </Query>
            </Col>
         </Row>

        


          
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
