import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import { convertToRaw, convertFromRaw, EditorState, ContentState, convertFromHTML  } from 'draft-js';

//import ReactMarkdown from 'react-markdown';
//import htmlParser from 'react-markdown/plugins/html-parser';


const restLink_conversation = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const conversation_client = new ApolloClient({
  link: restLink_conversation,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}



const DELETE_CHAT = gql`
  mutation deleteconversation($id: Int!){
    deleteConversation(id:$id) {
      id
    }
  }
`;


class ConversationList extends Component {

  ;

  render() {


    if(this.props.match.params.id) {
      this.conversation_id = this.props.match.params.id;
    } else {
      this.conversation_id = 0;
    }

    //const parseHtml = htmlParser({
    //  isValidNode: node => node.type !== 'script',
    //  processingInstructions: [/* ... */]
    //})

    function convertHtmlToElement(html) {
      var blocksFromHTML = convertFromHTML(html)
      var content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return content;
    }




    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/Conversation-new/'+(this.conversation_id ? this.conversation_id : "")}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Nova Resposta
              </NavLink>
              <Card
                title="Conversas"
                category="Gerenciar Conversas"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Possíveis Perguntas</th>
                        <th>Tipo</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={conversation_client}
                          fetchPolicy='no-cache'
                          variables={{conversation_id: this.conversation_id}}
                          query={gql`
                          query conversations_collections($conversation_id: Int!){
                            conversations_collections(limit:2000, order: "DESC", parent_id: $conversation_id){
                                conversations{
                                  id,
                                  title,
                                  question,
                                  answer,
                                  type
                                }
                              }
                            }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              
                              
                            return data.conversations_collections.conversations.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.question}</td>
                                <td>{item.type}</td>
                                <td>
                                  <p><a className="btn btn-info" href={getUrl('Conversation-subitens',item.id)}>Subitens</a></p>
                                </td>
                                <td>
                                  <p><a className="btn btn-info" href={getUrl('Conversation-edit',item.id)}>Editar</a></p>
                                </td>
                                <td>
                                  <p><a className="btn btn-success" href={getUrl('segmentation','Conversation',item.id)}>Segmentar</a></p>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                      confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            conversation_client.mutate({
                                              mutation: DELETE_CHAT,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });

                                            
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConversationList;
