import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_classroom = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
  const lms_client = new ApolloClient({
  link: restLink_classroom,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});


const ADD_TODO = gql`
  mutation createOrUpdateClassroom($id: Int!, $title: String!, $course_id: Int!, $address: String!, $limit_registrations: Int!, $datetime_start: String!, $datetime_end: String!, $datetime_registration_start: String!, $datetime_registration_end: String!, $active: Int!, $date_publish: String!, $date_unpublish: String!, $userowner_id: Int!, $teacher: String!, $is_public: Int!, $is_specific: Int!){
    createOrUpdateClassroom(id:$id, input:{title:$title, course_id:$course_id, address:$address, limit_registrations:$limit_registrations, datetime_start:$datetime_start, datetime_end:$datetime_end, datetime_registration_start:$datetime_registration_start, datetime_registration_end:$datetime_registration_end, active:$active, date_publish:$date_publish, date_unpublish:$date_unpublish, userowner_id: $userowner_id, teacher: $teacher, is_public: $is_public, is_specific: $is_specific}){
      id
    }
  }
`;

const GET_CLASSROOM = gql`
  query getClassroom($classroom_id: Int!){
    classroom(id:$classroom_id){
      id,
      title, 
      address,
      limit_registrations,
      datetime_start_format,
      datetime_end_format,
      datetime_registration_start_format,
      datetime_registration_end_format,
      active,
      date_publish_format,
      date_unpublish_format,
      userowner_id,
      teacher,
      is_public,
      is_specific,
      course{value:id, label:title}
    }
  }
`;

const GET_USER = gql`
query getUser($id: Int!){
  user(id:$id){
    name
  }
}
`;

const GET_USER_AUTH = gql`
{
  get_authenticated_user{
   id,name
 }
 }
`;


class ClassroomItem extends Component {

  classroom_id;
  classroom_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      checkboxValue_active: false,
      datePublish: new Date(),
      dateUnpublish: null,
      dateStart: null,
      dateEnd: null,
      dateRegistrationStart: null,
      dateRegistrationEnd: null,
      checkboxValue_is_public: true,
      checkboxValue_is_specific: false
    };


    /* 
    As variáveis abaixo, começam com start, que para definir o valor 
    Inicial dos campos, e elas existem para definir esse valor inicial 
    apenas quando a página carrega, pois depois são alteradas para "false" 
    */
    this.start_publish_date = true;
    this.start_unpublish_date = true;

    this.start_datetime_start = true;
    this.start_datetime_end = true;

    this.start_datetime_registration_start = true;
    this.start_datetime_registration_end = true;
    
    this.start_active = true;
    this.start_is_public = true;
    this.start_is_specific = true;
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  onChange_datetimeStart = datetimeStart => this.setState({ datetimeStart })
  onChange_datetimeEnd = datetimeEnd => this.setState({ datetimeEnd })

  onChange_datetimeRegistrationStart = datetimeRegistrationStart => this.setState({ datetimeRegistrationStart })
  onChange_datetimeRegistrationEnd = datetimeRegistrationEnd => this.setState({ datetimeRegistrationEnd })
  
  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_is_public = (e) => {

    this.setState(state => ({
      ...state,
      checkboxValue_is_public: !this.state.checkboxValue_is_public,
      checkboxValue_is_specific: this.state.checkboxValue_is_public
    }));

  }

  handleCheckboxChange_is_specific = (e) => {

    this.setState(state => ({
      ...state,
      checkboxValue_is_specific: !this.state.checkboxValue_is_specific,
      checkboxValue_is_public: this.state.checkboxValue_is_specific
    }));

  }
  
  render() {

    
    this.classroom_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.parent_id = (this.props.match.params.parent_id ? this.props.match.params.parent_id : 0);
    this.back_button = (this.props.match.params.parent_id ? "/SubClassroom/"+this.props.match.params.parent_id : "/Classroom" );

    return (

      <Query
      client={lms_client}
      query={GET_CLASSROOM}
      variables={{classroom_id:this.classroom_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.classroom_edit = data.classroom;

          if(this.start_active && this.classroom_edit && this.classroom_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.classroom_edit.active === 1 ? true : false)
            })
          }

          if(this.start_is_public && this.classroom_edit) {
            this.start_is_public = false;
            this.setState({
              checkboxValue_is_public: (this.classroom_edit.is_public === 1 ? true : false)
            })
          }

          if(this.start_is_specific && this.classroom_edit && this.classroom_edit.is_specific) {
            this.start_is_specific = false;
            this.setState({
              checkboxValue_is_specific: (this.classroom_edit.is_specific === 1 ? true : false)
            })
          }

          if(this.start_publish_date && this.classroom_edit && this.classroom_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.classroom_edit.date_publish_format)
            })
          }
          if(this.start_unpublish_date && this.classroom_edit && this.classroom_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.classroom_edit.date_unpublish_format)
            })
          }

          if(this.start_datetime_start && this.classroom_edit && this.classroom_edit.datetime_start_format) {
            this.start_datetime_start = false;
            this.setState({
              datetimeStart: new Date(this.classroom_edit.datetime_start_format)
            })
          }
          if(this.start_datetime_end && this.classroom_edit && this.classroom_edit.datetime_end_format) {
            this.start_datetime_end = false;
            this.setState({
              datetimeEnd: new Date(this.classroom_edit.datetime_end_format)
            })
          }

          if(this.start_datetime_registration_start && this.classroom_edit && this.classroom_edit.datetime_registration_start_format) {
            this.start_datetime_registration_start = false;
            this.setState({
              datetimeRegistrationStart: new Date(this.classroom_edit.datetime_registration_start_format)
            })
          }
          if(this.start_datetime_registration_end && this.classroom_edit && this.classroom_edit.datetime_registration_end_format) {
            this.start_datetime_registration_end = false;
            this.setState({
              datetimeRegistrationEnd: new Date(this.classroom_edit.datetime_registration_end_format)
            })
          }

        return (

      <Mutation mutation={ADD_TODO} client={lms_client}>
      {(createOrUpdateClassroom, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to={this.back_button}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Turma"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateClassroom({variables: { 
                      id: this.classroom_id, 
                      course_id: parseInt(e.target.elements.course_id.value),
                      title: "-",
                      address: e.target.elements.address.value,
                      limit_registrations: parseInt(e.target.elements.limit_registrations.value),
                      datetime_start: e.target.elements.datetime_start.value,
                      datetime_end: e.target.elements.datetime_end.value,
                      datetime_registration_start: e.target.elements.datetime_registration_start.value,
                      datetime_registration_end: e.target.elements.datetime_registration_end.value,
                      active: (e.target.elements.active.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value,
                      userowner_id:  parseInt(e.target.elements.userowner_id.value),
                      teacher:  e.target.elements.teacher.value,
                      is_public: (e.target.elements.is_public.checked ? 1 : 0),
                      is_specific: (e.target.elements.is_specific.checked ? 1 : 0)
                    } });
                  }}
                  >

              

                <div>Criador: 
                  {this.classroom_edit && this.classroom_edit.userowner_id ?           
                      <Query
                        client={account_client}
                        variables={{id: this.classroom_edit.userowner_id}}
                        query={GET_USER}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <tr><td>Carregando...</td></tr>;
                          if (error) return <tr><td>Erro :(</td></tr>;
                            
                            return (
                              <div>
                              {data.user.name}
                              </div>

                            )
                            }
                        }
                        </Query>

                        : <div></div>}

                  </div>

                  <hr />


                {this.classroom_edit &&
                <div>ID da Turma: {this.classroom_edit.id}</div> 
                }

                  <Query
                        client={account_client}
                        query={GET_USER_AUTH}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;
                            
                            return (
                              <div>
                              <input id="userowner_id" name="userowner_id" type="hidden" value={(this.classroom_edit ? this.classroom_edit.userowner_id : data.get_authenticated_user.id)} />
                              </div>

                            )
                            }
                        }
                        </Query>


                  <hr />

                  <FormGroup>
                    <ControlLabel>Curso presencial</ControlLabel>
                    <div>
                      <Query
                          client={lms_client}
                          query={gql`{
                            courses_collections(type:"presential"){
                              courses{
                                value:id, label:title
                              }
                            }
                          }`}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                            return (
                              <Select
                                name="course_id"
                                options={data.courses_collections ? data.courses_collections.courses : []}
                                className="basic-select"
                                classNamePrefix="select"
                                defaultValue={this.classroom_edit && this.classroom_edit ? this.classroom_edit.course : ""}
                              />
                              );
                            }}
                      </Query>
                    </div>
                  </FormGroup>


                


                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "address",
                        label: "Endereço",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Endereço",
                        defaultValue: (this.classroom_edit ? this.classroom_edit.address : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "teacher",
                        label: "Instrutor",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Instrutor",
                        required: true,
                        defaultValue: (this.classroom_edit ? this.classroom_edit.teacher : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "limit_registrations",
                        label: "Número máximo de pessoas",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Número máximo de pessoas",
                        defaultValue: (this.classroom_edit ? this.classroom_edit.limit_registrations : "")
                      }
                    ]}
                  />

                  <hr />
                  <FormGroup>
                    <ControlLabel>Início do curso</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_start"
                        onChange={this.onChange_datetimeStart}
                        value={this.state.datetimeStart}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Fim do curso</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_end"
                        onChange={this.onChange_datetimeEnd}
                        value={this.state.datetimeEnd}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <hr /> 
                  <FormGroup>
                    <ControlLabel>Início das inscrições</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_registration_start"
                        onChange={this.onChange_datetimeRegistrationStart}
                        value={this.state.datetimeRegistrationStart}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Término das inscrições</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_registration_end"
                        onChange={this.onChange_datetimeRegistrationEnd}
                        value={this.state.datetimeRegistrationEnd}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>


                  

                <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="is_public"
                        type="checkbox"
                        checked={this.state.checkboxValue_is_public}
                        onChange={this.handleCheckboxChange_is_public}
                      /> Pública
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="is_specific"
                        type="checkbox"
                        checked={this.state.checkboxValue_is_specific}
                        onChange={this.handleCheckboxChange_is_specific}
                      /> Específica
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default ClassroomItem;
