import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import Button from "../../components/CustomButton/CustomButton.jsx";
import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { Query } from "react-apollo";

import { Chat } from "../../components/Chat/Chat.jsx";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

const restLink_chat = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const chat_client = new ApolloClient({
  link: restLink_chat,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ credentials: 'include', uri: "/api/v1/account/" });
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_analytics = new HttpLink({ credentials: 'include', uri: "/api/v1/analytics/" });
const analytics_client = new ApolloClient({
  link: restLink_analytics,
  cache: new InMemoryCache(),
});

const GET_USERS = gql`
  query getusers($chat_id: Int!, $status: String!){
    getUsersByChat(chat_id:$chat_id, status: $status) {
      ChatHasUser{
        id
        user_id
        name
        mail
        uid
        profile_json
      }
    }
  }
`;

const COUNT_USERS = gql`
  query countusers($chat_id: Int!, $status: String!){
      countUsersByChat(chat_id:$chat_id, status: $status) {
        count
      }
    }
`;

const COUNT_USERS_PROFILES = gql`
  query countusers($chat_id: Int!, $status: String!)  {
    countUsersProfilesByChat(chat_id:$chat_id, status:$status, fields:["Perfil", "Comercial", "Regional", "Sucursal", "Diretoria"]) {
    	AccountFieldCount{
        field
        values{
            account_field_value
            count
        }
      }
    }
  }
`;

const GET_CHAT = gql`
  query getChat($chat_id: Int!){
    chat(id:$chat_id){
      title,
      description,
      active,
      date_publish_format,
      date_unpublish_format,
      liveid,
      moderation,
    }
  }
`;

const APROVED_TOGGLE = gql`
  mutation AddTodo($id: Int!, $approved: Int!){
    approvedToggle(id:$id,approved:$approved){
      id
    }
  }
`;

const IS_HIDDEN_TOGGLE = gql`
  mutation AddTodo($id: Int!, $is_hidden: Int!){
    isHiddenToggle(id:$id,is_hidden:$is_hidden){
      id
    }
  }
`;

const IS_QUESTION_TOGGLE = gql`
  mutation AddTodo($id: Int!, $is_question: Int!){
    isQuestionToggle(id:$id,is_question:$is_question){
      id
    }
  }
`;

const GET_CHAT_MSG = gql`
  query getChatMessages($chat_id: Int!) {
    chat_message_collections(chat_id:$chat_id){
      messages{
        id,
        message,
        user_name,
        user_id,
        approved,
        is_hidden,
        is_question,
        response_from{
          id,
          user_id,
          user_name,
          message
        }
      }
    }
  }
`;





class Chatbot extends Component {

  state = {
  };

  componentDidMount() {
  }

  componentWillUnmount() {
    clearInterval(this.loggedUsersInterval)
  }

  render() {


    return (

      <div className="content">
       

        <Grid fluid>
          <Row>
              <Col md={5}>
                <Chat chat_id={7} />
              </Col>
            </Row>
      </Grid>
       
      </div>
    );
  }




}

export default Chatbot;
