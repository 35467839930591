import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Select from 'react-select';
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";


const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'  
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const CREATE_SEGMENTATION = gql`
  mutation createItem($account_field_value_id: Int!, $account_module_name: String!, $item_id: Int!){
    createSegmentation(input:{
      account_field_value_id: $account_field_value_id,
      account_module_name:$account_module_name,
      item_id: $item_id
    }) {
      id
    }
  }
`;

const DELETE_SEGMENTATION = gql`
mutation deleteItem($account_field_value_id: Int!, $account_module_name: String!, $item_id: Int!){
  deleteSegmentation(
    account_field_value_id: $account_field_value_id,
    account_module_name:$account_module_name,
    item_id: $item_id
  ) {
      id
    }
  }
`;



class Segmentation extends Component {

  controllerItens = [];
  urlBack;

  constructor() {
    super();
     this.state = {
       defaultOptions : [],
       currentInputChange : false
    }

  }

  
  onChange = (inputValue, action ) => {
    // Adicionar
    if(action.action === 'select-option') {
      account_client.mutate({
        mutation: CREATE_SEGMENTATION,
        variables: {
          account_field_value_id: action.option.value, account_module_name: this.props.match.params.module_name, item_id: this.props.match.params.id
        }
      });
    } 
    // Remover
    else if(action.action === 'remove-value') {
      account_client.mutate({
        mutation: DELETE_SEGMENTATION,
        variables: {
          account_field_value_id: action.removedValue.value, account_module_name: this.props.match.params.module_name, item_id: this.props.match.params.id
        }
      });
    }
 
  } 

  /*
  updateDefaultValues(account_field_id, module_name, item_id) {
    account_client.query({
      fetchPolicy:'no-cache',
      variables:{account_field_id: account_field_id, module_name: module_name, item_id: item_id},
      query:gql`
      query getItens($account_field_id: Int!, $module_name: String!, $item_id: Int!){
        get_segmentations(account_field_id: $account_field_id, module_name:$module_name, item_id:$item_id){
          segmentations{
            account_field_value {
              value:id, label:value
            }
          }
        }
      }`
    })
    .then(
    result => {
      var data = result.data.get_segmentations.segmentations.map((segmentations) => {return segmentations.account_field_value; });
      console.log(data);
      this.setState({
        defaultValue: {[account_field_id] : data}
      });
    }
    );
  }*/

  onInputChange = (inputValue, action ) => {
    var account_field_id = this.state.currentInputChange;
    var props = this.props;
    // Se tiver vírgula, trata todos    
    if(inputValue.indexOf(",") !== -1) {
      var noExist = "";
      // Quebra por vírgula
      var inputValue_split = inputValue.split(',')
      inputValue_split.map((item, k) => {
        var value = item.trim();
        account_client.query({
          variables:{account_field_id: account_field_id, value: value},
          query: gql`
          query getAccountFieldValue($account_field_id: Int!, $value: String!){
            get_account_field_value(account_field_id: $account_field_id, value: $value) {
                id
            }
          }
          `
      })
      .then(
        result => {
          // Se o item exisgir, adciona
          if(result.data.get_account_field_value && result.data.get_account_field_value.id) {
            account_client.mutate({
              mutation: CREATE_SEGMENTATION,
              variables: {
                account_field_value_id: result.data.get_account_field_value.id, account_module_name: props.match.params.module_name, item_id: props.match.params.id
              }
            });
          } else{
            noExist += (value+", ");
          }
          
          
          // Após o ultimo item, atualiza a página, para mostrar os inseridos
          if(inputValue_split.length === k+1) {
            if(noExist !== ""){
              // Se for o último item, exibe todos CPFs não cadastrados, por não existir na base
              alert("Itens não cadastrados por não existir na base: "+noExist);
            }
            window.location.reload()
          }
          
          
        }
      );
      });

      

    }
    // Só faz a busca dos conteúdos search tiver mais de três de caracteres, e não tiver vírgula, pois se tiver vírgula, são vários conteúdos
    else if(inputValue.length >= 3){
      account_client.query({
          variables:{account_field_id: account_field_id, search: inputValue},
          query: gql`
          query getAccountFieldValues($account_field_id: Int!, $search: String!){
            get_account_field_values(account_field_id:$account_field_id, search: $search) {
            accountfieldvalues{
              value:id, label:value
            }
          }
        }`
      })
      .then(
        result => {
          this.setState({
            options: {[account_field_id] : result.data.get_account_field_values.accountfieldvalues}
          });
        }
      );
    }
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    

  }

  render() {
    
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <a
                  href='javascript:history.go(-1)'
                  className="btn btn-info btn-fill"
                >
                VOLTAR
              </a>
              <NavLink
                  to={'/segmentation/'+this.props.match.params.module_name+"/"+this.props.match.params.id}
                  className="btn btn-success btn-fill"
                  activeClassName=""
                >
                Segmentar por listas
              </NavLink>
              <NavLink
                  to={'/segmentation-import/'+this.props.match.params.module_name+"/"+this.props.match.params.id}
                  className="btn btn-success"
                  activeClassName=""
                >
                Segmentar por importação
              </NavLink>

              <Card
                title={this.props.match.params.module_name+" - Segmentação"}
                category="Definir quem pode ver esse conteúdo"
                ctTableFullWidth
                ctTableResponsive
                content={

                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                  }}
                  >
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                        
                        <Query
                          client={account_client}
                          query={gql`
                          {
                            get_account_fields(type: "dropdown"){
                              accountfields{
                                id, field, type
                              }
                            }
                          }
                          `}
                        >
                          {({ loading, error, data }) => {

                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              return data.get_account_fields.accountfields.map((account_field) => (


                              // Faz outra busca para pegar itens já selecionados
                              <Query
                                client={account_client}
                                variables={{account_field_id: account_field.id, module_name:this.props.match.params.module_name, item_id:this.props.match.params.id}}
                                query={gql`
                                query getItens($account_field_id: Int!, $module_name: String!, $item_id: Int!){
                                  get_segmentations(account_field_id: $account_field_id, module_name:$module_name, item_id:$item_id){
                                    segmentations{
                                      account_field_value {
                                        value:id, label:value
                                      }
                                    }
                                  }
                                }
                                `}
                              >
                                {({ loading, error, data }) => {
      
                                  if (loading) return <tr><td>Carregando...</td></tr>;
                                  if (error) return <tr><td>Erro :(</td></tr>;
      
                                    const defaultValues = data.get_segmentations.segmentations.map((segmentations) => {return segmentations.account_field_value; });
                                    this.controllerItens[account_field.id] = defaultValues;


                                    if(account_field.type == 'all') {
                                            return (
                                                <tr key={account_field.id}>
                                                  <td>{account_field.field} (Digite ou cole vários separados por vírgula)</td>
                                                  <td>
                                                    <Select
                                                          defaultValue={this.state.defaultValue && this.state.defaultValue[account_field.id] ? this.state.defaultValue[account_field.id] : defaultValues}
                                                          isMulti
                                                          isSearchable
                                                          name={"values_"+account_field.id}
                                                          options={this.state.options && this.state.options[account_field.id] ? this.state.options[account_field.id] : []}
                                                          className="basic-multi-select"
                                                          classNamePrefix="select"
                                                          //inputValue={inputValue}
                                                          onChange={this.onChange}
                                                          onInputChange={this.onInputChange}
                                                          onFocus={() => this.setState({currentInputChange: account_field.id})}
                                                          isClearable={true}
                                                          onInputChange={this.onInputChange}
                                                        />
                                                  </td>
                                                </tr>
                                            );
                                    } 
                                    else {

                                            return (

                                              <Query // Faz a busca de todas possibildiades quando for autocomplete
                                                client={account_client}
                                                variables={{account_field_id:account_field.id}}
                                                query={gql`
                                                query getAccountFieldValues($account_field_id: Int!){
                                                    get_account_field_values(account_field_id:$account_field_id) {
                                                    accountfieldvalues{
                                                      value:id, label:value
                                                    }
                                                  }
                                                }
                                                `}
                                              >
                                                {({ loading, error, data }) => {

                                                  if (loading) return <tr><td>Carregando...</td></tr>;
                                                  if (error) return <tr><td>Erro :(</td></tr>;
                                                    
                                                  if (!data.get_account_field_values.accountfieldvalues || !data.get_account_field_values.accountfieldvalues.length)  return "";
                                                    
                                                    var account_field_values = data.get_account_field_values.accountfieldvalues;
                                                    return (
                                                          
                                                                <tr key={account_field.id}>
                                                                  <td>{account_field.field}</td>
                                                                  <td>
                                                                    
                                                                  <Select
                                                                    defaultValue={defaultValues}
                                                                    isMulti
                                                                    isSearchable
                                                                    name={"values_"+account_field.id}
                                                                    options={account_field_values}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    //inputValue={inputValue}
                                                                    onChange={this.onChange}
                                                                    isClearable={true}
                                                                    onInputChange={this.onInputChange}
                                                                  />
                                                                  </td>
                                                                </tr>
                                                              
                                                              );
                                                    }}

                                                </Query>
                                                
                                                );
                                          }

                                    }}
                                  </Query>
                            ));
                          }}
                        </Query>
                        <tr>
                          <td>
                          <Button bsStyle="info" pullRight fill onClick={() => {window.location.reload()}}>
                            Salvar
                          </Button>
                          </td>
                          <td></td>
                        </tr>
                    </tbody>
                  </Table>
                  

                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Segmentation;
