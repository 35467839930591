import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const restLink_cms = new HttpLink({
  uri: "/api/v1/cms/",
  credentials: 'include'
});
const cms_client = new ApolloClient({
  link: restLink_cms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_DAM = gql`
  query getCms($module_name: String!){
    contents_collections(limit:200,order:"Desc",module_name:$module_name) {
      contents{
        id, title
      }
    }
  }
`;


const DELETE_DAM = gql`
  mutation deleteCms($id: Int!){
    deleteContent(id:$id) {
      id
    }
  }
`;


class CmsList extends Component {


  render() {
    console.log(this.props.match.params);
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/Cms-new/'+this.props.match.params.module_name}
                  className="btn btn-success btn-fill"
                  activeClassName=""
                >
                Novo 
              </NavLink>
              <Card
                title={this.props.match.params.module_name}
                category="Gerenciar"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={cms_client}
                          variables={{
                            module_name:this.props.match.params.module_name
                          }}
                          query={GET_DAM}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.contents_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.contents_collections.contents.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>
                                  <a className="btn btn-primary" href={getUrl('cms-edit',this.props.match.params.module_name,item.id)}>Thumb</a>
                                </td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('cms-edit',this.props.match.params.module_name,item.id)}>Editar</a>
                                </td>
                                <td>
                                  <a className="btn btn-success" href={getUrl('segmentation',this.props.match.params.module_name,item.id)}>Segmentar</a>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                    console.log(item.id);
                                    confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            cms_client.mutate({
                                              mutation: DELETE_DAM,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CmsList;
