import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_trail = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink_trail,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({ credentials: 'include', uri: "/api/v1/dam/" });
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});


const GET_CATEGORIES = gql`
  {
    category_collections{
      categories{
        value:id,label:title
      }
    }
  }
`;

const GET_CATEGORY = gql`
  query getCategory($id: Int!){
    category(id:$id) {
      value:id,label:title
    }
  }
`;

const ADD_TODO = gql`
  mutation createOrUpdateTrail($id: Int!, $category_id: Int!, $title: String!, $reactionevaluation_id: Int!, $proof_id: Int!, $active: Int!, $blockcontent: Int!, $date_publish: String!, $date_unpublish: String!){
    createOrUpdateTrail(id:$id, input:{title:$title, category_id:$category_id, reactionevaluation_id:$reactionevaluation_id, active:$active, proof_id:$proof_id, blockcontent:$blockcontent, date_publish:$date_publish, date_unpublish:$date_unpublish}){
      id
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id){
      id, title, category_id, thumbnail, active, blockcontent, date_publish_format, date_unpublish_format, reactionevaluation{
        value:id, label:title
      }, proof{
        value:id, label:title
      }
    }
  }
`;


const GET_PROOFS = gql`query getProofs($type_id: Int!){
  proof_collections(type_id: $type_id){
    proofs{
      value:id, label:title
    }
  }
}
`;

class TrailItem extends Component {

  trail_id;
  trail_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null
    };


    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_blockcontent = true;
    
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_blockcontent = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_blockcontent: !this.state.checkboxValue_blockcontent
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  
  render() {

    
    if(this.props.match.params.id) {
      this.trail_id = this.props.match.params.id;
    } else {
      this.trail_id = 0;
    }

    return (


      <Query
      client={lms_client}
      query={GET_TRAIL}
      variables={{trail_id:this.trail_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.trail_edit = data.trail;

          if(this.start_publish_date && this.trail_edit && this.trail_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.trail_edit.date_publish_format)
            })
          }
          if(this.start_unpublish_date && this.trail_edit && this.trail_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.trail_edit.date_unpublish_format)
            })
          }
          if(this.start_active && this.trail_edit && this.trail_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.trail_edit.active === 1 ? true : false)
            })
          }
          if(this.start_blockcontent && this.trail_edit && this.trail_edit.blockcontent) {
            this.start_blockcontent = false;
            this.setState({
              checkboxValue_blockcontent: (this.trail_edit.blockcontent === 1 ? true : false)
            })
          }

        return (

      <Mutation mutation={ADD_TODO} client={lms_client}>
      {(createOrUpdateTrail, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Trail"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Trilha"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateTrail({variables: { 
                      id: parseInt(this.trail_id), 
                      category_id: (e.target.elements.category_id.value ? e.target.elements.category_id.value : 0),
                      title: e.target.elements.title.value,
                      reactionevaluation_id: (e.target.elements.reactionevaluation_id.value ? parseInt(e.target.elements.reactionevaluation_id.value) : 0),
                      proof_id: (e.target.elements.proof_id.value ? parseInt(e.target.elements.proof_id.value) : 0),
                      active: (e.target.elements.active.checked ? 1 : 0),
                      blockcontent: (e.target.elements.blockcontent.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value
                    } });
                  }}
                  >

                  

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.trail_edit ? this.trail_edit.title : "")
                      }
                    ]}
                  />

              <FormGroup>
                    <ControlLabel>Categoria</ControlLabel>
                    <div>
                      <Query
                          client={dam_client}
                          query={GET_CATEGORIES}
                          fetchPolicy='no-cache'
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var categories = data;
                            return (
                              <Query
                                  variables={{id: (this.trail_edit && this.trail_edit.category_id ? this.trail_edit.category_id : 0)}}
                                  client={dam_client}
                                  query={GET_CATEGORY}
                                  >
                                  {({ loading, error, data }) => {
                                    if (loading) return <div>Carregando...</div>;
                                    if (error) return <div>Erro :(</div>;
                                      var category = data;
                                      return (
                                          <Select
                                            name="category_id"
                                            options={categories.category_collections ? categories.category_collections.categories : []}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            defaultValue={category.category && this.trail_edit ? category.category : ""}
                                          />
                                      );
                                }}
                              </Query>
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <FormGroup>
                  <ControlLabel>Avaliação de reação</ControlLabel>
                    <div>
                      <Query
                          variables={{type_id: 2}} // 2 é o ID da avaliçaão de reação no microseviço de avalações
                          client={lms_client}
                          query={GET_PROOFS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var proofs = data;
                            return (
                                <Select
                                  name="reactionevaluation_id"
                                  options={proofs.proof_collections ? proofs.proof_collections.proofs : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.trail_edit && this.trail_edit.reactionevaluation ? this.trail_edit.reactionevaluation : ""}
                                />
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <FormGroup>
                  <ControlLabel>Prova</ControlLabel>
                    <div>
                      <Query
                          variables={{type_id: 1}} // 1 é o ID da prova no microseviço de avalações
                          client={lms_client}
                          query={GET_PROOFS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var proofs = data;
                            return (
                                <Select
                                  name="proof_id"
                                  options={proofs.proof_collections ? proofs.proof_collections.proofs : []}
                                  className="basic-select"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  defaultValue={this.trail_edit && this.trail_edit.proof ? this.trail_edit.proof : ""}
                                />
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="blockcontent"
                        type="checkbox"
                        checked={this.state.checkboxValue_blockcontent}
                        onChange={this.handleCheckboxChange_blockcontent}
                      /> Bloquear conteúdos 
                    </ControlLabel>
                  </FormGroup>

                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

        <Card
                title={this.trail_edit ?"Thumbnail":""}
                content={this.trail_edit ?
                  <form 
                  action="/api/v1/lms/trail_thumb" method="post" enctype='multipart/form-data'
                  >
                  
                  <input type="hidden" name="returnUrl" value={"/#/Trail-edit/"+this.trail_edit.id} />
                  <input type="hidden" name="id" value={this.trail_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div></div>}
              />

              <Card
                title=""
                content={this.trail_edit && this.trail_edit.thumbnail ?
                  <div><img alt="thumb" src={"/lms/assets/"+this.trail_edit.thumbnail} height="100px" /></div>
              : <div></div>}
              />
              
            
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default TrailItem;
