import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const restLink_classroom = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const classroom_client = new ApolloClient({
  link: restLink_classroom,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ 
  uri: "/api/v1/account/",
  credentials: 'include'
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_CLASSROOM = gql`
  query getClassroom($search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
    classroom_collections(search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby){
      classroom{
        id, title, datetime_start_format, userowner_id, course{title}
      }
    }
  }
`;

const COUNT_CLASSROOM = gql`query countClassroom($search: String!){
  classroom_count(search:$search){
    count
  }
}
`;

const DELETE_CLASSROOM = gql`
  mutation deleteClassroom($id: Int!){
    deleteClassroom(id:$id) {
      id
    }
  }
`;


class ClassroomList extends Component {

  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/Classroom-new'}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Nova Turma Presencial
              </NavLink>
              <Card
                title={"Turmas Presenciais"}
                classroom="Gerenciar turmas presenciais"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                      <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                              <Row>
                                  <Col md={6} lg={3}>
                                      <input  type="text" name="search" className="form-control" />
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} lg={1}>
                                      <select name="orderby" className="form-control">
                                        <option value="id">ID</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th width="80">
                          <a onClick={this.toggleOrder}>
                            <i className={"fas fas "+(this.state.order == 'DESC' ? 'fa-angle-up' : 'fa-angle-down')}></i>
                          </a>
                          ID
                        </th>
                        <th>Curso</th>
                        <th>Segmentação</th>
                        <th>Data / Hora</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={classroom_client}
                          query={GET_CLASSROOM}
                          variables={{
                            search: this.state.search, 
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.classroom_collections.classroom) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.classroom_collections.classroom.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.course.title}</td>
                                <td>
                                <Query
                                      client={account_client}
                                      variables={{account_field_id: 10, module_name:"Classroom", item_id:item.id}}
                                      query={gql`
                                      query getItens($account_field_id: Int!, $module_name: String!, $item_id: Int!){
                                        get_segmentations(account_field_id: $account_field_id, module_name:$module_name, item_id:$item_id){
                                          segmentations{
                                            account_field_value {
                                              value
                                            }
                                          }
                                        }
                                      }
                                      `}
                                    >
                                      {({ loading, error, data }) => {
            
                                        if (loading) return <tr><td>Carregando...</td></tr>;
                                        if (error) return <tr><td>Erro :(</td></tr>;
            
                                          return data.get_segmentations.segmentations.map((account_field_value) => (
                                            <div>
                                              {account_field_value.account_field_value.value}
                                            </div>
                                          ));
                                    }}

                                </Query>  
                                <Query
                                      client={account_client}
                                      variables={{account_field_id: 9, module_name:"Classroom", item_id:item.id}}
                                      query={gql`
                                      query getItens($account_field_id: Int!, $module_name: String!, $item_id: Int!){
                                        get_segmentations(account_field_id: $account_field_id, module_name:$module_name, item_id:$item_id){
                                          segmentations{
                                            account_field_value {
                                              value
                                            }
                                          }
                                        }
                                      }
                                      `}
                                    >
                                      {({ loading, error, data }) => {
            
                                        if (loading) return <tr><td>Carregando...</td></tr>;
                                        if (error) return <tr><td>Erro :(</td></tr>;
            
                                          return data.get_segmentations.segmentations.map((account_field_value) => (
                                            <div>
                                              {account_field_value.account_field_value.value}
                                            </div>
                                          ));
                                    }}

                                </Query>        
                                </td>
                                <td>
                                {new Date(item.datetime_start_format).toLocaleDateString("pt-BR")} <br />
                                Início: {new Date(item.datetime_start_format).getHours()}h {new Date(item.datetime_start_format).getMinutes()}min
                                </td>

                                <td>
                                  <a className="btn btn-info" href={"/api/v1/lms/subscription_export?classroom_id="+item.id}>Baixar lista</a>
                                </td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('Classroom-subscription',item.id)}>Lista de presença</a>
                                </td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('classroom-edit',item.id)}>Editar</a>
                                </td>
                                <td>
                                  <a className="btn btn-success" href={getUrl('segmentation', "Classroom",item.id)}>Segmentar</a>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                      confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            classroom_client.mutate({
                                              mutation: DELETE_CLASSROOM,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });

                                            
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        <Query
                          client={classroom_client}
                          query={COUNT_CLASSROOM}
                          variables={{
                            search: this.state.search
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.classroom_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ClassroomList;
