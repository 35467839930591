import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import { NavLink } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

class CourseUpload extends Component {
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to="/Course/"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Upload Scorm do curso"
                content={
                  <form 
                  action="/api/v1/lms/course_upload" method="post" enctype='multipart/form-data'
                  >
                  <input type="hidden" name="returnUrl" value={"/#/Course"} />
                  
                  <input
                  type="file"
                  name="file"
                  required
                  />
                  
                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CourseUpload;
