import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';


const restLink_account = new HttpLink({
  uri: "/api/v1/account/",
  credentials: 'include'
  });
const account_client = new ApolloClient({
 link: restLink_account,
 cache: new InMemoryCache(),
});


class HeaderLinks extends Component {
  render() {
    // const notification = (
    //   <div>
    //     <i className="fa fa-globe" />
    //     <b className="caret" />
    //     <span className="notification">5</span>
    //     <p className="hidden-lg hidden-md">Notification</p>
    //   </div>
    // );
    return (
      <div>
        <Nav>
          <NavItem eventKey={1} href="/#/dashboard">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
        </Nav>
        <Nav pullRight>
          <NavItem eventKey={1}>
            <Query
              client={account_client}
                query={gql`{
                  get_authenticated_user{
                    id,
                    first_name
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Olá</div>;
                if (error) return <div>Erro :(</div>;
                  
                return  (
                  <div>Olá, {data.get_authenticated_user.name}</div>
                );
              }}
            </Query>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default HeaderLinks;
