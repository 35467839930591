import Auth from "../views/Auth/Auth";
import Login from "../views/Auth/Login";

import Home from "../views/Home/Home";
import Dashboard from "../views/Dashboard/Dashboard";
import UserProfile from "../views/UserProfile/UserProfile";

import Segmentation from "../views/Segmentation/Segmentation";
import SegmentationImport from "../views/Segmentation/SegmentationImport";

import Cms from "../views/Cms/Cms";
import CmsItem from "../views/Cms/CmsItem";

import Dam from "../views/Dam/Dam";
import DamItem from "../views/Dam/DamItem";
import DamUpload from "../views/Dam/DamUpload";


import Category from "../views/Category/Category";
import SubCategory from "../views/Category/SubCategory";
import CategoryItem from "../views/Category/CategoryItem";

import CategoryCourse from "../views/CategoryCourse/Category";
import SubCategoryCourse from "../views/CategoryCourse/SubCategory";
import CategoryCourseItem from "../views/CategoryCourse/CategoryItem";

import UserList from "../views/User/UserList";
import UserItem from "../views/User/UserItem";

import CovidRiskList from "../views/CovidRisk/CovidRiskList";
import CovidRiskItem from "../views/CovidRisk/CovidRiskItem";
import CovidRiskPointsList from "../views/CovidRisk/CovidRiskPointsList";
import CovidRiskPointsItem from "../views/CovidRisk/CovidRiskPointsItem";

import CorretorList from "../views/Corretor/CadastroList";
import CorretorItem from "../views/Corretor/CadastroItem";

import ParceiroList from "../views/Parceiro/CadastroList";
import ParceiroItem from "../views/Parceiro/CadastroItem";

import ProofList from "../views/Course/ProofList";
import ProofItem from "../views/Course/ProofItem";

import QuestionList from "../views/Course/QuestionList";
import QuestionItem from "../views/Course/QuestionItem";

import AnswerList from "../views/Course/AnswerList";
import AnswerItem from "../views/Course/AnswerItem";

import Course from "../views/Course/Course";
import CourseItem from "../views/Course/CourseItem";
import CourseUpload from "../views/Course/CourseUpload";

import CoursePresentialList from "../views/Course/CoursePresentialList";
import CoursePresentialItem from "../views/Course/CoursePresentialItem";

import ClassroomList from "../views/Course/ClassroomList";
import ClassroomItem from "../views/Course/ClassroomItem";
import Subscription from "../views/Course/Subscription";



import Trail from "../views/Trail/Trail";
import TrailItem from "../views/Trail/TrailItem";

import Conversation from "../views/Chat/Conversation";
import ConversationItem from "../views/Chat/ConversationItem";
import Chatbot from "../views/Chat/Chatbot";

import Communication from "../views/Chat/Communication";
import CommunicationItem from "../views/Chat/CommunicationItem";
import CommunicationHasUser from "../views/Chat/CommunicationHasUser";


import Chat from "../views/Chat/Chat";
import ChatItem from "../views/Chat/ChatItem";
import ChatModerate from "../views/Chat/Moderate";
import ChatCalendar from "../views/Chat/Calendar";

import TableList from "../views/TableList/TableList";
import Typography from "../views/Typography/Typography";
import Icons from "../views/Icons/Icons";
import Maps from "../views/Maps/Maps";
import Notifications from "../views/Notifications/Notifications";
import TrailHasCourse from "../views/Trail/TrailHasCourse";

import Pluzbot from "../views/Chat/Widget";




const dashboardRoutes = [
  {
    showSidebar: false,
    path: "/login",
    name: "Login",
    icon: "pe-7s-users",
    component: Login
  },
/* Chatbot */
{
  showSidebar: false,
  path: "/pluzbot/:active",
  name: "Pluzbot",
  icon: "pe-7s-albums",
  component: Pluzbot
},
{
  showSidebar: false,
  path: "/pluzbot/",
  name: "Pluzbot",
  icon: "pe-7s-albums",
  component: Pluzbot
},
  {
    showSidebar: false,
    path: "/pluzbot-widget/:token",
    name: "Pluzbot",
    icon: "pe-7s-albums",
    component: Pluzbot
  },

  /* ASSETS GERAL */
  
  {
    showSidebar: false,
    path: "/Dam/:type/:external_parent_id",
    name: "Item",
    icon: "pe-7s-albums",
    component: Dam
  },
  {
    showSidebar: false,
    path: "/Dam-edit/:type/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamItem
  },
  {
    showSidebar: false,
    path: "/Dam-new/:type/:external_parent_id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },
  {
    showSidebar: false,
    path: "/Dam-new/:type",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },


  {
    default: true,
    showSidebar: true,
    path: "/home",
    name: "Início",
    icon: "pe-7s-home",
    component: Home
  },
  {
    showSidebar: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    module: "Dashboard"
  },
  {
    showSidebar: false,
    path: "/auth/:userid",
    name: "Auth",
    icon: "pe-7s-graph",
    component: Auth
  },
  {
    showSidebar: false,
    path: "/profile",
    name: "User Profile",
    icon: "pe-7s-user",
    component: UserProfile
  },

  {
    showSidebar: false,
    path: "/segmentation/:module_name/:id",
    name: "Segmentation",
    icon: "pe-7s-user",
    component: Segmentation
  },
  {
    showSidebar: false,
    path: "/segmentation-import/:module_name/:id",
    name: "SegmentationImport",
    icon: "pe-7s-user",
    component: SegmentationImport
  },


  /* USUÁRIOS */
  {
    showSidebar: true,
    path: "/User",
    name: "Usuários",
    icon: "pe-7s-users",
    component: UserList,
    module: "User"
  },
  {
    showSidebar: false,
    path: "/User-edit/:id",
    name: "User Edit",
    icon: "pe-7s-users",
    component: UserItem
  },


  /* COVID RISK */
  {
    showSidebar: true,
    path: "/CovidRisk",
    name: "Fatores",
    icon: "pe-7s-science",
    component: CovidRiskList,
    module: "CovidRisk"
  },
  {
    showSidebar: false,
    path: "/CovidRisk-new",
    name: "CovidRisk Edit",
    icon: "pe-7s-science",
    component: CovidRiskItem
  },
  {
    showSidebar: false,
    path: "/CovidRisk-edit/:id",
    name: "CovidRisk Edit",
    icon: "pe-7s-science",
    component: CovidRiskItem
  },
  {
    showSidebar: false,
    path: "/CovidRiskPoints/:covid_risk_id",
    name: "Riscos",
    icon: "pe-7s-science",
    component: CovidRiskPointsList,
    module: "CovidRisk"
  },
  {
    showSidebar: false,
    path: "/CovidRiskPoints-new/:covid_risk_id",
    name: "CovidRisk Edit",
    icon: "pe-7s-science",
    component: CovidRiskPointsItem
  },
  {
    showSidebar: false,
    path: "/CovidRiskPoints-edit/:covid_risk_id/:id",
    name: "CovidRisk Edit",
    icon: "pe-7s-science",
    component: CovidRiskPointsItem
  },



  /* CATEGORIAS DE CURSOS 
  {
    showSidebar: true,
    path: "/course-category",
    name: "Categorias dos Cursos",
    icon: "pe-7s-menu",
    component: CategoryCourse,
    module: "Category"
  },
  {
    showSidebar: false,
    path: "/course-subcategory/:parent_id",
    name: "Subcategorias",
    icon: "pe-7s-menu",
    component: SubCategoryCourse
  },
  {
    showSidebar: false,
    path: "/course-category-new",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryCourseItem
  },
  {
    showSidebar: false,
    path: "/course-subcategory-new/:parent_id",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryCourseItem
  },
  {
    showSidebar: false,
    path: "/course-category-edit/:id",
    name: "Category Edit",
    icon: "pe-7s-menu",
    component: CategoryCourseItem
  },
*/

  /* CATEGORIAS */
  {
    showSidebar: false,
    path: "/Category",
    name: "Categorias",
    icon: "pe-7s-menu",
    component: Category,
    module: "Category"
  },
  {
    showSidebar: false,
    path: "/SubCategory/:parent_id",
    name: "Subcategorias",
    icon: "pe-7s-menu",
    component: SubCategory
  },
  {
    showSidebar: false,
    path: "/Category-new",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryItem
  },
  {
    showSidebar: false,
    path: "/SubCategory-new/:parent_id",
    name: "Category Item",
    icon: "pe-7s-menu",
    component: CategoryItem
  },
  {
    showSidebar: false,
    path: "/Category-edit/:id",
    name: "Category Edit",
    icon: "pe-7s-menu",
    component: CategoryItem
  },

  /* CONTENT */
  {
    showSidebar: false,
    path: "/Cms-new/:module_name",
    name: "Item",
    icon: "pe-7s-albums",
    component: CmsItem
  },
  {
    showSidebar: false,
    path: "/Cms-edit/:module_name/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: CmsItem
  },

  /* CHAT BOT */


  {
    showSidebar: true,
    path: "/Conversation",
    name: "ChatBot Base",
    icon: "pe-7s-chat",
    component: Conversation,
    module: "Conversation"
  },
  {
    showSidebar: false,
    path: "/Conversation-subitens/:id",
    name: "ChatBot Base",
    icon: "pe-7s-chat",
    component: Conversation,
    module: "Conversation"
  },
  {
    showSidebar: false,
    path: "/Conversation-new/:parent_id",
    name: "Conversation Item",
    icon: "pe-7s-chat",
    component: ConversationItem
  },
  {
    showSidebar: false,
    path: "/Conversation-new",
    name: "Conversation Item",
    icon: "pe-7s-chat",
    component: ConversationItem
  },
  {
    showSidebar: false,
    path: "/Conversation-edit/:id",
    name: "Conversation Item",
    icon: "pe-7s-chat",
    component: ConversationItem
  },

  {
    showSidebar: true,
    path: "/Communication",
    name: "Comunicado Geral",
    icon: "pe-7s-chat",
    component: Communication,
    module: "Communication"
  },
  {
    showSidebar: false,
    path: "/Communication-new",
    name: "Comunicação Item",
    icon: "pe-7s-chat",
    component: CommunicationItem
  },
  {
    showSidebar: false,
    path: "/Communication-edit/:id",
    name: "Comunicação Item",
    icon: "pe-7s-chat",
    component: CommunicationItem
  },
  {
    showSidebar: false,
    path: "/CommunicationHasUser/:id",
    name: "Comunicação - Visualizações",
    icon: "pe-7s-chat",
    component: CommunicationHasUser
  },

  {
    showSidebar: false,
    path: "/Chatbot/",
    name: "Chatbot",
    icon: "pe-7s-chat",
    component: Chatbot
  },

  {
    showSidebar: true,
    path: "/Chat",
    name: "Chamados",
    icon: "pe-7s-chat",
    component: Chat,
    module: "Chat"
  },

  
  /* CHAT */
  {
    showSidebar: false,
    path: "/Dam/:type",
    link: "/Dam/chatBanner",
    name: "Banner Sala Virt.",
    icon: "pe-7s-albums",
    component: Dam,
    module: "ChatBanner"
  },
  {
    showSidebar: false,
    path: "/Chat-new",
    name: "Chat Item",
    icon: "pe-7s-chat",
    component: ChatItem
  },
  {
    showSidebar: false,
    path: "/Chat-edit/:id",
    name: "Chat Item",
    icon: "pe-7s-chat",
    component: ChatItem
  },
  {
    showSidebar: false,
    path: "/Moderate/:id",
    name: "Chat Moderate",
    icon: "pe-7s-chat",
    component: ChatModerate
  },
  {
    showSidebar: false,
    path: "/Chat-Calendar/",
    name: "Chat Calendar",
    icon: "pe-7s-chat",
    component: ChatCalendar
  },
  {
    showSidebar: false,
    path: "/Chat-Calendar/",
    name: "Chat Calendar",
    icon: "pe-7s-chat",
    component: ChatCalendar
  },


  /* MEDIA CENTER */
  {
    showSidebar: false,
    path: "/Dam/:type",
    link: "/Dam/Media",
    name: "Conteúdos digitais",
    icon: "pe-7s-photo",
    component: Dam,
    module: "Media"
  },


  /* CMS GERAL */
  {
    showSidebar: false,
    path: "/Cms-edit/:module_name/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: CmsItem
  },


  /* DEPOIMENTOS */
  {
    showSidebar: false,
    path: "/Cms/:module_name",
    link: "/Cms/Testimony",
    name: "Depoimentos",
    icon: "pe-7s-speaker",
    component: Cms,
    module: "Testimony"
  },

  /* BANNER */
  {
    showSidebar: false,
    path: "/Dam/:type",
    link: "/Dam/Banner",
    name: "Banner",
    icon: "pe-7s-albums",
    component: Dam,
    module: "Banner"
  },
  
  /* Avaliação e Avaliação de reação Edit */
  {
    showSidebar: false,
    path: "/Proof-new/:type_id",
    name: "Proof",
    icon: "pe-7s-note",
    component: ProofItem
  },
  {
    showSidebar: false,
    path: "/Proof-edit/:type_id/:id",
    name: "Proof",
    icon: "pe-7s-note",
    component: ProofItem
  },
  {
    showSidebar: false,
    path: "/Question/:type_id/:proof_id",
    name: "Question",
    icon: "pe-7s-note",
    component: QuestionList,
    module: "Proof"
  },
  {
    showSidebar: false,
    path: "/Question-new/:type_id/:proof_id",
    name: "Question",
    icon: "pe-7s-note",
    component: QuestionItem
  },
  {
    showSidebar: false,
    path: "/Question-edit/:type_id/:proof_id/:id",
    name: "Question",
    icon: "pe-7s-note",
    component: QuestionItem
  },

  {
    showSidebar: false,
    path: "/Answer/:type_id/:proof_id/:question_id",
    name: "Answer",
    icon: "pe-7s-note",
    component: AnswerList,
    module: "Proof"
  },
  {
    showSidebar: false,
    path: "/Answer-new/:type_id/:proof_id/:question_id",
    name: "Answer",
    icon: "pe-7s-note",
    component: AnswerItem
  },
  {
    showSidebar: false,
    path: "/Answer-edit/:type_id/:proof_id/:question_id/:id",
    name: "Answer",
    icon: "pe-7s-note",
    component: AnswerItem
  },

  /* Avaliação */
  {
    showSidebar: false,
    path: "/Proof/:type_id",
    link: "/Proof/1",
    name: "Avaliação",
    icon: "pe-7s-note",
    component: ProofList,
    module: "Proof"
  },
  
  

  /* Avaliação de Reação */
  {
    showSidebar: false,
    path: "/Proof/:type_id",
    link: "/Proof/2",
    name: "Aval. de reação",
    icon: "pe-7s-note",
    component: ProofList,
    module: "Proof"
  },

  /* Quiz */
  {
    showSidebar: false,
    path: "/Proof/:type_id",
    link: "/Proof/3",
    name: "Quiz",
    icon: "pe-7s-note",
    component: ProofList,
    module: "Proof"
  },

  /* CURSOS PRESENCIAIS */
  {
    showSidebar: false,
    path: "/Presential-course",
    name: "Cursos Presenciais",
    icon: "pe-7s-study",
    component: CoursePresentialList,
    module: "Course"
  },
  {
    showSidebar: false,
    path: "/Presential-course-new",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CoursePresentialItem
  },
  {
    showSidebar: false,
    path: "/Presential-course-edit/:id",
    name: "Course Presential Edit",
    icon: "pe-7s-menu",
    component: CoursePresentialItem
  },

  /* TURMAS PRESENCIAIS */
  {
    showSidebar: false,
    path: "/Classroom",
    name: "Turmas Presenciais",
    icon: "pe-7s-study",
    component: ClassroomList,
    module: "Classroom"
  },
  {
    showSidebar: false,
    path: "/Classroom-new",
    name: "Turma Item",
    icon: "pe-7s-menu",
    component: ClassroomItem
  },
  {
    showSidebar: false,
    path: "/Classroom-edit/:id",
    name: "Turma Presential Edit",
    icon: "pe-7s-menu",
    component: ClassroomItem
  },
  {
    showSidebar: false,
    path: "/Classroom-subscription/:classroom_id",
    name: "Turma Presential Edit",
    icon: "pe-7s-menu",
    component: Subscription
  },
  


  /* CURSOS */
  {
    showSidebar: false,
    path: "/Course",
    name: "Cursos On-line",
    icon: "pe-7s-study",
    component: Course,
    module: "Course"
  },
  {
    showSidebar: false,
    path: "/Course-new-scorm",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CourseUpload
  },
  {
    showSidebar: false,
    path: "/Course-new-video",
    name: "Course Item",
    icon: "pe-7s-menu",
    component: CourseItem
  },
  {
    showSidebar: false,
    path: "/Course-edit/:id",
    name: "Course Edit",
    icon: "pe-7s-menu",
    component: CourseItem
  },
  /* ASSETS DE CONTEÚDOS EXTERNOS, COMO OS ASSETS DE CURSOS */
  {
    showSidebar: false,
    path: "/DamCourse/:type/:external_parent_id",
    name: "Banner",
    icon: "pe-7s-albums",
    component: Dam,
    module: "Banner"
  },
  {
    showSidebar: false,
    path: "/DamCourse-new/:type/:external_parent_id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamUpload
  },
  {
    showSidebar: false,
    path: "/DamCourse-edit/:type/:external_parent_id/:id",
    name: "Item",
    icon: "pe-7s-albums",
    component: DamItem
  },

  /* TRILHA */
  {
    showSidebar: false,
    path: "/trail",
    name: "Trilhas",
    icon: "pe-7s-next-2",
    component: Trail,
    module: "Trail"
  },
  {
    showSidebar: false,
    path: "/Trail-new",
    name: "Trail Item",
    icon: "pe-7s-menu",
    component: TrailItem
  },
  {
    showSidebar: false,
    path: "/Trail-edit/:id",
    name: "Trail Edit",
    icon: "pe-7s-menu",
    component: TrailItem
  },
  {
    showSidebar: false,
    path: "/Trail-has-course/:id",
    name: "Trail",
    icon: "pe-7s-menu",
    component: TrailHasCourse
  },


  /* OUTROS */
  {
    showSidebar: false,
    path: "/ser",
    name: "Usuários",
    icon: "pe-7s-users",
    component: Chat
  },
  {
    showSidebar: false,
    path: "/table",
    name: "Table List",
    icon: "pe-7s-note2",
    component: TableList
  },
  {
    showSidebar: false,
    path: "/typography",
    name: "Typography",
    icon: "pe-7s-news-paper",
    component: Typography
  },
  { showSidebar: false, path: "/icons", name: "Icons", icon: "pe-7s-science", component: Icons },
  { showSidebar: false, path: "/maps", name: "Maps", icon: "pe-7s-map-marker", component: Maps },
  {
    showSidebar: false,
    path: "/notifications",
    name: "Notifications",
    icon: "pe-7s-bell",
    component: Notifications
  },
  { redirect: true, path: "/", to: "/home", name: "Home" }
];

export default dashboardRoutes;
