import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


const restLink_cms = new HttpLink({ credentials: 'include', uri: "/api/v1/cms/" });
//const restLink_cms = new HttpLink({ credentials: 'include', uri: "/api/v1/cms/" });
const cms_client = new ApolloClient({
  link: restLink_cms,
  cache: new InMemoryCache(),
});



const ADD_TODO = gql`
  mutation createOrUpdateContent($id: Int!, $title: String!, $active: Int!,  $date_publish: String!, $date_unpublish: String!,  $content: String!,  $module_name: String!){
    createOrUpdateContent(id:$id, input:{title:$title, active:$active, date_publish:$date_publish, date_unpublish:$date_unpublish, content:$content, module_name:$module_name }){
      id
    }
  }
`;

const GET_ASSET = gql`
  query getContent($cms_id: Int!){
    content(id:$cms_id){
      id, title, content, thumbnail, active, date_publish_format, date_unpublish_format
    }
  }
`;






class CmsItem extends Component {

  cms_id;
  cms_edit;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      checkboxValue_active: false,
      datePublish: new Date(),
      dateUnpublish: null
    };
    
    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    
  }

  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    if(this.props.match.params.id) {
      this.cms_id = this.props.match.params.id;
    } else {
      this.cms_id = 0;
    }

    return (


      <Query
      client={cms_client}
      query={GET_ASSET}
      variables={{cms_id:this.cms_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.cms_edit = data.content;

          if(this.start_publish_date && this.cms_edit && this.cms_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.cms_edit.date_publish_format)
            })
          }
          if(this.start_unpublish_date && this.cms_edit && this.cms_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.cms_edit.date_unpublish_format)
            })
          }
          if(this.start_active && this.cms_edit && this.cms_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.cms_edit.active === "true" ? true : false)
            })
          }
          
        return (

      <Mutation mutation={ADD_TODO} client={cms_client}>
      {(createOrUpdateCms, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to={'/Cms/'+this.props.match.params.module_name}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title={this.props.match.params.module_name}
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateCms({variables: { 
                      id: this.cms_id, 
                      title: e.target.elements.title.value,
                      active: (e.target.elements.active.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value,
                      content: e.target.elements.content.value,
                      module_name: this.props.match.params.module_name
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.cms_edit ? this.cms_edit.title : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "content",
                        label: "Conteúdo",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Conteúdo",
                        defaultValue: (this.cms_edit ? this.cms_edit.content : "")
                      }
                    ]}
                  />


                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>


                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

            <Card
                title={this.cms_edit ?"Thumbnail":""}
                content={this.cms_edit ?
                  <form 
                  action="/api/v1/cms/cms_thumb" method="post" enctype='multipart/form-data'
                  >
                  
                  <input type="hidden" name="returnUrl" value={"/#/cms-edit/"+this.props.match.params.module_name+"/"+this.cms_edit.id} />
                  <input type="hidden" name="id" value={this.cms_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div></div>}
              />

              <Card
                title=""
                content={this.cms_edit && this.cms_edit.thumbnail ?
                  <div><img alt="thumb" src={"/cms/assets/"+this.cms_edit.thumbnail} height="100px" /></div>
              : <div></div>}
              />
              
            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default CmsItem;
