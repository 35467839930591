import React, { Component } from "react";
import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';
import { Chat } from "../../components/Chat/Chat.jsx";


const restLink_chat = new HttpLink({
  uri: "/api/v1/chat/",
  credentials: 'include'
 });
const chat_client = new ApolloClient({
 link: restLink_chat,
 cache: new InMemoryCache(),
});

const GET_CURRENT_CHAT = gql`
{
  currentChat{
    id
  }
}
`;


class Widget extends Component {

  constructor() {
    super();


  }

  render() {

    var enable = false;
    var chat_started = false;
    if(this.props.match.params.active && this.props.match.params.active == 1) {
      enable = true;
      if(this.chat_started === undefined) {
        chat_started = true;
        this.chat_started = true;
      }
    } 
    
    return (
      <Query
          client={chat_client}
          query={GET_CURRENT_CHAT}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;

            return (
          <div id="pluzbot" className="chat-enable">
            <div id="pluzbot_chat">
              <Chat enable={enable} chat_started={chat_started} chat_id={data.currentChat.id} />
            </div>
          </div>
          );
        }}
      </Query>
    );
  }
}

export default Widget;
