import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { HttpLink } from 'apollo-link-http';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

const restLink_covidRisk = new HttpLink({ credentials: 'include', uri: "/api/v1/chat/" });
const dam_client = new ApolloClient({
  link: restLink_covidRisk,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateCovidRiskPoints($id: Int!, $days: Int, $points: Int, $points2: Int, $points3: Int, $covid_risk_id: Int!){
    createOrUpdateCovidRiskPoints(id:$id, input:{days:$days, points:$points, points2:$points2, points3:$points3, covid_risk_id:$covid_risk_id}){
      id
    }
  }
`;

const GET_COVID_RISK_POINTS = gql`
  query getCovidRisk($covidRisk_id: Int!){
    covidRiskPoints(id:$covidRisk_id){
      id
      days
      points
      points2
      points3
    }
  }
`;



class CovidRiskItem extends Component {

  covidRisk_id;
  covidRiskPoints_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: ""
    };

    this.start_active = true;
    
  }

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    var _notificationSystem = this.refs.notificationSystem;

    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.covidRiskPoints_id = (this.props.match.params.id ? parseInt(this.props.match.params.id) : 0);
    this.covid_risk_id = parseInt(this.props.match.params.covid_risk_id);
    this.back_button = "/CovidRiskPoints/"+this.covid_risk_id;



    return (


      <Query
      client={dam_client}
      query={GET_COVID_RISK_POINTS}
      variables={{covidRisk_id:this.covidRiskPoints_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.covidRiskPoints_edit = data.covidRiskPoints;


        return (

      <Mutation mutation={ADD_TODO} client={dam_client}>
      {(createOrUpdateCovidRisk, { data }) => (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>

            <NavLink
                to={this.back_button}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
              VOLTAR
            </NavLink>
            <NotificationSystem ref="notificationSystem" style={style} />
            
              <Card
                title="Pontuação do Fator"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateCovidRisk({variables: { 
                      id: this.covidRiskPoints_id, 
                      covid_risk_id: this.covid_risk_id,
                      days: parseInt(e.target.elements.days.value),
                      points: parseInt(e.target.elements.points.value),
                      points2: parseInt(e.target.elements.points2.value),
                      points3: parseInt(e.target.elements.points3.value)
                    } });
                  }}
                  >

                <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "days",
                        label: "Dias",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Dias",
                        defaultValue: (this.covidRiskPoints_edit ? this.covidRiskPoints_edit.days : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "points",
                        label: "Pontuação Diagnóstico",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Pontuação Diagnóstico",
                        defaultValue: (this.covidRiskPoints_edit ? this.covidRiskPoints_edit.points : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "points2",
                        label: "Pontuação Risco",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Pontuação Risco",
                        defaultValue: (this.covidRiskPoints_edit ? this.covidRiskPoints_edit.points2 : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "points3",
                        label: "Pontuação Forma",
                        type: "number",
                        bsClass: "form-control",
                        placeholder: "Pontuação Forma",
                        defaultValue: (this.covidRiskPoints_edit ? this.covidRiskPoints_edit.points3 : "")
                      }
                    ]}
                  />
                  
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />


            </Col>
            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default CovidRiskItem;
