import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { confirmAlert } from 'react-confirm-alert'; 


const restLink_trail = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const trail_client = new ApolloClient({
  link: restLink_trail,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_TRAIL = gql`
  query getTrail($search: String!, $limit: Int!, $page: Int!, $order: String!, $orderby: String!){
    trails_collections(search: $search, limit: $limit, page: $page, order: $order, orderby: $orderby){
      trails{
        id,title
      }
    }
  }
`;

const COUNT_TRAIL = gql`query countTrail($search: String!){
  trail_count(search:$search){
    count
  }
}
`;


const DELETE_TRAIL = gql`
   mutation deleteTrail($id: Int!){
     deleteTrail(id:$id) {
       id
     }
   }
 `;


class TrailList extends Component {


  state = {
    search: '',
    limit: 20,
    page: 1,
    orderby: "id",
    order: "DESC"
  }

  toggleOrder = (event) => {
    this.setState({
      order: (this.state.order == 'DESC'? 'ASC' : 'DESC')
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value
    });

  }

  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/Trail-new/'}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Novo Trilha
              </NavLink>
              <Card
                title={"Trilhas ativos"}
                trail="Gerenciar trails ativos"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                      <th colSpan={9}>
                            <form 
                            onSubmit={e => {
                              this.handleSubmit(e);
                              e.preventDefault();
                            }}
                            >
                              <Row>
                                  <Col md={6} lg={3}>
                                      <input  type="text" name="search" className="form-control" />
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Buscar
                                    </Button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} lg={1}>
                                      <select name="orderby" className="form-control">
                                        <option value="id">ID</option>
                                        <option value="title">Título</option>
                                      </select>
                                  </Col>
                                  <Col md={6} lg={2}>
                                      <select name="order" className="form-control">
                                        <option value="DESC">Decrescente</option>
                                        <option value="ASC" selected={this.state.order == "ASC"}>Crescente</option>
                                      </select>
                                  </Col>
                                  <Col md={2}>
                                    <Button bsStyle="info" fill type="submit" >
                                      Ordenar
                                    </Button>
                                  </Col>
                                </Row>
                            </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={trail_client}
                          query={GET_TRAIL}
                          variables={{
                            search: this.state.search, 
                            limit: this.state.limit, 
                            page: this.state.page, 
                            order: this.state.order, 
                            orderby: this.state.orderby
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.trails_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.trails_collections.trails.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>
                                  <a className="btn btn-info" href={"/api/v1/lms/report_trail?trail_id="+item.id}>Baixar relatório</a>
                                </td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('Trail-has-course',item.id)}>Cursos</a>
                                </td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('Trail-edit',item.id)}>Editar</a>
                                </td>
                                <td>
                                  <a className="btn btn-success" href={getUrl('segmentation', "Trail",item.id)}>Segmentar</a>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                       var r = confirmAlert({
                                       title: '',
                                       message: "Tem certeza que deseja excluir "+item.title,
                                       buttons: [
                                         {
                                           label: 'Sim',
                                           onClick: () => {
                                             // Delete item
                                             trail_client.mutate({
                                               mutation: DELETE_TRAIL,
                                               variables: {
                                                 id:item.id
                                               }
                                             }).then(
                                              result => {
                                                 window.location.reload();
                                             });
                                           }
                                         },
                                         {
                                           label: 'Não'
                                         }
                                       ]
                                     });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                        <Query
                          client={trail_client}
                          query={COUNT_TRAIL}
                          variables={{
                            search: this.state.search
                          }}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;

                              var pagination_buttons = [];
                              var total_pages = data.trail_count.count/this.state.limit;
                              for(var page_num = 1; Math.ceil(total_pages) >= page_num; page_num++){
                                pagination_buttons.push(<spam><spam page_num={page_num} className={"btn_page "+(this.state.page==page_num ? 'btn_page_active' : '')} onClick={e => {this.setState({page: e.target.getAttribute('page_num')})}} >{page_num}</spam> </spam>);
                              }
                            return (
                              <tr key={1}>
                                <td colSpan={6}>
                                  {pagination_buttons}
                                </td>
                              </tr>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TrailList;
