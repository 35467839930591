import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import Button from "../../components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const restLink_category = new HttpLink({ credentials: 'include', uri: "/api/v1/lms/" });
const category_client = new ApolloClient({
  link: restLink_category,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_CATEGORIES = gql`
query getCagegories($parent_id:Int!){
    category_collections(parent:$parent_id){
      categories{
        id,title
      }
    }
  }
`;



const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: Int!){
    deleteCategory(id:$id) {
      id
    }
  }
`;


class CategoryList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to={'/course-category'}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                VOLTAR
              </NavLink>
              <NavLink
                  to={'/course-subcategory-new/'+this.props.match.params.parent_id}
                  className="btn btn-success btn-fill"
                  activeClassName=""
                >
                Nova Subcategoria
              </NavLink>
              <Card
                title={"Categorias ativas"}
                category="Gerenciar categorias ativos"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={category_client}
                          query={GET_CATEGORIES}
                          variables={{parent_id:this.props.match.params.parent_id}}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.category_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.category_collections.categories.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>
                                  <a className="btn btn-info" href={getUrl('course-category-edit',item.id)}>Editar</a>
                                </td>
                                <td>
                                  <a className="btn btn-success" href={getUrl('segmentation','CategoryCourse',item.id)}>Segmentar</a>
                                </td>
                                <td>
                                <Button bsStyle="danger" onClick={() => {
                                    console.log(item.id);
                                    confirmAlert({
                                      title: '',
                                      message: "Tem certeza que deseja excluir "+item.title,
                                      buttons: [
                                        {
                                          label: 'Sim',
                                          onClick: () => {
                                            // Delete item
                                            category_client.mutate({
                                              mutation: DELETE_CATEGORY,
                                              variables: {
                                                id:item.id
                                              }
                                            }).then(
                                              result => {
                                                window.location.reload();
                                            });
                                          }
                                        },
                                        {
                                          label: 'Não'
                                        }
                                      ]
                                    });
                                    
                                  }}>
                                  Excluir
                                </Button>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CategoryList;
