import React, { Component } from "react";
import cover from "../../assets/img/cover.jpg";
import edu_cover from "../../assets/img/edu.jpg";

import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";

import Button from "../CustomButton/CustomButton.jsx";
import Parser from "html-react-parser";

export class Message extends Component {
  render() {
    const parseHtml = htmlParser({
      isValidNode: (node) => node.type !== "script",
      processingInstructions: [
        /* ... */
      ],
    });

    return (
      <div
        className={
          this.props.is_admin ? "chat_message_admin" : "chat_message_user"
        }
      >
        <div
          className={
            this.props.response_from
              ? "chat_message chat_response"
              : "chat_message"
          }
        >
          {this.props.response_from ? (
            <div className="chat_answered_question">
              <b>{this.props.response_from.user_name}</b>
              <p>{this.props.response_from.message}</p>
            </div>
          ) : (
            <div></div>
          )}
          <div className="chat_answer">
            <div className="chat_thumb">
              <img src={this.props.is_admin == 1 ? edu_cover : cover} alt="" />
            </div>
            <b>{this.props.user_name}</b>
            <ReactMarkdown
              source={this.props.message}
              escapeHtml={false}
              astPlugins={[parseHtml]}
              linkTarget="_blank"
            />

            {this.props.iframe && (
              <div className="embed-responsive embed-responsive-4by3 card shadow-sm">
                <iframe
                  className="embed-responsive-item"
                  src={this.props.iframe}
                  allowfullscreen=""
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  scrolling="no"
                  frameborder="0"
                  allow="geolocation; microphone; camera; encrypted-media; midi"
                />
              </div>
            )}

            {
              // Exibe os subitens como botoes de decisão
              this.props.subitens && this.props.subitens.length > 0 && (
                <div className="braching">
                  {this.props.subitens.map((subitem, i) => (
                    <Button
                      key={i}
                      bsStyle="info"
                      onClick={() => {
                        this.props.chatElement.newUserMessage_click(
                          subitem.title
                        );
                      }}
                    >
                      {subitem.title}
                    </Button>
                  ))}
                </div>
              )
            }

            {this.props.messagesRelated &&
              this.props.messagesRelated.length > 0 && (
                <div>
                  <p>
                    Estou adicionando aqui outros assuntos relacionados, clique
                    no link que irei te auxiliar.
                  </p>

                  <ul>
                    {this.props.messagesRelated.map((messageRelated, i) => (
                      <li>
                        <a
                          bsStyle="info"
                          onClick={() => {
                            this.props.chatElement.newUserMessage(
                              messageRelated.conversation.title
                            );
                          }}
                        >
                          {messageRelated.conversation.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            {/* {this.props.is_admin == 1 && this.props.is_answered && (
              <div>
                <p>Consegui te ajudar?</p>
                <p>
                  <Button
                    bsStyle="info"
                    onClick={() => {
                      this.props.chatElement.newUserMessage("Sim");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    bsStyle="info"
                    onClick={() => {
                      this.props.chatElement.newUserMessage("Não");
                    }}
                  >
                    Não
                  </Button>
                </p>
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default Message;
