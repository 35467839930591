import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Select from 'react-select';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({value}) => <li>{value}</li>);
const SortableList = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({ 
  uri: "/api/v1/lms/",
  credentials: 'include'
 });
const lms_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

/* TRAIL HAS CURSE */
const CREATE_TRAILHASCURSE = gql`
  mutation createItem($course_id: Int!, $trail_id: Int!){
    createTrailHasCourse(input:{
      course_id: $course_id,
      trail_id: $trail_id
    }) {
      id
    }
  }
`;

const DELETE_TRAILHASCURSE = gql`
mutation deleteItem($course_id: Int!, $trail_id: Int!){
  deleteTrailHasCourse(
    course_id: $course_id,
    trail_id: $trail_id
  ) {
      id
    }
  }
`;
const GET_TRAILHASCURSE = gql`
  query getItens($trail_id: Int!){
    trailhascourse(trail_id:$trail_id) {
      trailhascourses{
        course{
          value:id, label:title
        }
      }
    }
  }
`;

/* TRAIL HAS ASSET */
const CREATE_TRAILHASASSET = gql`
  mutation createItem($asset_id: Int!, $trail_id: Int!){
    createTrailHasAsset(input:{
      asset_id: $asset_id,
      trail_id: $trail_id
    }) {
      id
    }
  }
`;

const DELETE_TRAILHASASSET = gql`
mutation deleteItem($asset_id: Int!, $trail_id: Int!){
  deleteTrailHasAsset(
    asset_id: $asset_id,
    trail_id: $trail_id
  ) {
      id
    }
  }
`;
const GET_TRAILHASASSET = gql`
  query getItens($trail_id: Int!){
    trailhasasset(trail_id:$trail_id) {
      trailhasassets{
        asset_id
      }
    }
  }
`;

const GET_DAM = gql`
  query getDam($module_name: String!, $external_parent_id: Int!){
    assets_collections(limit:200,order:"Desc",module_name:$module_name,external_parent_id:$external_parent_id) {
      assets{
        value:id, label:title
      }
    }
  }
`;

/* TRAIL ITEMS */
const CREATE_TRAILITEMS = gql`
  mutation createItem($item_id: Int!, $item_type: String!, $trail_id: Int!){
    createTrailItems(input:{
      item_id: $item_id,
      item_type: $item_type,
      trail_id: $trail_id
    }) {
      id
    }
  }
`;

const DELETE_TRAILITEMS = gql`
mutation deleteItem($item_id: Int!, $item_type: String!, $trail_id: Int!){
  deleteTrailItems(
    item_id: $item_id,
    item_type: $item_type,
    trail_id: $trail_id
  ) {
      id
    }
  }
`;
const GET_TRAILITEMS = gql`
  query getItens($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        item_id, item_type
      }
    }
  }
`;

const UPDATEORDER_TRAILITEMS = gql`
  mutation updateOrderTrailItems($item_id: Int!, $item_type: String!, $trail_id: Int!, $ordernum: Int!){
    updateOrderTrailItems(input:{
      item_id: $item_id,
      item_type: $item_type,
      trail_id: $trail_id,
      ordernum: $ordernum
    }) {
      id
    }
  }
`;


const GET_CURSE = gql`
  query getCourse($course_id: Int!){
    course(id:$course_id){
      id, title
    }
  }
`;

const GET_ASSET = gql`
  query getAsset($dam_id: Int!){
    asset(id:$dam_id){
      id, title
    }
  }
`;


class TrailHasCourse extends Component {

  controllerItens = [];
  urlBack;
  start_sortable = true;

  constructor() {
    super();
     this.state = {
       defaultOptions : [],
       items: [],
       ids_items: [],
    }

  }
  
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    this.state.items.forEach((item, key) => {
      var item_id_and_type = this.state.ids_items[item];
      lms_client.mutate({
        mutation: UPDATEORDER_TRAILITEMS,
        variables: {
          item_id: item_id_and_type.id, 
          item_type: item_id_and_type.type, 
          trail_id: parseInt(this.props.match.params.id),
          ordernum: key+1
        }
      });
      
    });
  };
  
  onChange_course = (inputValue, action) => {
    let title_to_sort, add_items, add_ids_items;
    // Adicionar
    if(action.action === 'select-option') {
      title_to_sort = "Curso #"+action.option.value+" - "+action.option.label;

      add_items = this.state.items;
      add_items.push(title_to_sort);

      add_ids_items = this.state.ids_items;
      add_ids_items[title_to_sort] = {id:action.option.value, type:"course"};

      this.setState(state => ({
        ...state,
        items: add_items,
        ids_items: add_ids_items
      }));

      // Atualiza o banco
      lms_client.mutate({
        mutation: CREATE_TRAILHASCURSE,
        variables: {
          course_id: action.option.value, 
          trail_id: parseInt(this.props.match.params.id)
        }
      });

      lms_client.mutate({
        mutation: CREATE_TRAILITEMS,
        variables: {
          item_id: action.option.value, 
          item_type: "course",
          trail_id: parseInt(this.props.match.params.id)
        }
      });
    } 
    // Remover
    else if(action.action === 'remove-value') {

      title_to_sort = "Curso #"+action.removedValue.value+" - "+action.removedValue.label;

      add_items = this.state.items;
      var removeByIndex = add_items.indexOf(title_to_sort);
      add_items.splice(removeByIndex, 1);

      add_ids_items = this.state.ids_items;
      add_ids_items[title_to_sort] = null;

      this.setState(state => ({
        ...state,
        items: add_items,
        ids_items: add_ids_items
      }));

      // Atualiza o banco
      lms_client.mutate({
        mutation: DELETE_TRAILHASCURSE,
        variables: {
          course_id: action.removedValue.value, 
          trail_id: parseInt(this.props.match.params.id)
        }
      });

      lms_client.mutate({
        mutation: DELETE_TRAILITEMS,
        variables: {
          item_id: action.removedValue.value, 
          item_type: "course", 
          trail_id: parseInt(this.props.match.params.id)
        }
      });
    }
 
  }

  onChange_asset = (inputValue, action ) => {
    let title_to_sort, add_items, add_ids_items;
    // Adicionar
    if(action.action === 'select-option') {
      title_to_sort = "Cont. Digital #"+action.option.value+" - "+action.option.label;

      add_items = this.state.items;
      add_items.push(title_to_sort);

      add_ids_items = this.state.ids_items;
      add_ids_items[title_to_sort] = {id:action.option.value, type:"asset"};

      this.setState(state => ({
        ...state,
        items: add_items,
        ids_items: add_ids_items
      }));

      // Atualiza o banco
      lms_client.mutate({
        mutation: CREATE_TRAILHASASSET,
        variables: {
          asset_id: action.option.value, trail_id: parseInt(this.props.match.params.id)
        }
      });

      lms_client.mutate({
        mutation: CREATE_TRAILITEMS,
        variables: {
          item_id: action.option.value, 
          item_type: "asset",
          trail_id: parseInt(this.props.match.params.id)
        }
      });
    } 
    // Remover
    else if(action.action === 'remove-value') {

      title_to_sort = "Cont. Digital #"+action.removedValue.value+" - "+action.removedValue.label;

      add_items = this.state.items;
      var removeByIndex = add_items.indexOf(title_to_sort);
      add_items.splice(removeByIndex, 1);

      add_ids_items = this.state.ids_items;
      add_ids_items[title_to_sort] = false;

      this.setState(state => ({
        ...state,
        items: add_items,
        ids_items: add_ids_items
      }));

      // Atualiza o banco
      lms_client.mutate({
        mutation: DELETE_TRAILHASASSET,
        variables: {
          asset_id: action.removedValue.value, trail_id: parseInt(this.props.match.params.id)
        }
      });

      lms_client.mutate({
        mutation: DELETE_TRAILITEMS,
        variables: {
          item_id: action.removedValue.value, 
          item_type: "asset", 
          trail_id: parseInt(this.props.match.params.id)
        }
      });
    }
 
  }

  sortableItems(trailitems) {

    var items = this.state.items;
    var ids_items = this.state.ids_items;

    trailitems.forEach((item) => {
     
      if(item.item_type === 'course') {

        lms_client.query({
            variables:{course_id:item.item_id},
            query: GET_CURSE
        })
        .then(
          result => {
            var title_to_sort = "Curso #"+result.data.course.id+" - "+result.data.course.title;
            items.push(title_to_sort);
            ids_items[title_to_sort] = {id:result.data.course.id, type:"course"};

            this.setState(state => ({
              ...state,
              items: items,
              ids_items: ids_items
            }));
        });

      } else if(item.item_type === 'asset') {

        dam_client.query({
            variables:{dam_id:item.item_id},
            query: GET_ASSET
        })
        .then(
          result => {
            var title_to_sort = "Cont. Digital #"+result.data.asset.id+" - "+result.data.asset.title;
            items.push(title_to_sort);
            ids_items[title_to_sort] = {id:result.data.asset.id, type:"asset"};

            this.setState(state => ({
              ...state,
              items: items,
              ids_items: ids_items
            }));
        });

      }

    });
  }
  

  render() {

    const inputValue = '';
   
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <NavLink
                  to="/Trail"
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                VOLTAR
              </NavLink>

              <Card
                title=" Cursos para trilha"
                category="Definir os cursos dessa trilha"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                        
                        <Query
                          client={lms_client}
                          query={gql`
                          {
                            courses_collections(type:"online"){
                              courses{
                                value:id, label:title
                              }
                            }
                          }
                          `}
                        >
                          {({ loading, error, data }) => {

                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              const optionsValues_course = data.courses_collections.courses;
                              
                              return (
                              
                                // Faz outra busca para pegar itens já selecionados
                                <Query
                                  client={lms_client}
                                  variables={{trail_id: parseInt(this.props.match.params.id)}}
                                  query={GET_TRAILHASCURSE}
                                >
                                  {({ loading, error, data }) => {
        
                                    if (loading) return <tr><td>Carregando...</td></tr>;
                                    if (error) return <tr><td>Erro :(</td></tr>;
        
                                      const defaultValues = data.trailhascourse.trailhascourses.map((item) => {
                                        return item.course;
                                      });

                                      return (
                                      
                                            <tr>
                                              <td>
                                              <Select
                                                defaultValue={defaultValues}
                                                isMulti
                                                name={"value"}
                                                options={optionsValues_course}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={this.onChange_course}
                                                isClearable={false}
                                                onInputChange={this.onInputChange}
                                              />
                                              </td>
                                            </tr>
                                          
                                          );
                                }}

                            </Query>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>

          <Row>
          <Col md={12}>
          <Card
                title="Conteúdos Digitais para trilha"
                category="Definir os conteúdos digitais dessa trilha"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                        
                        <Query
                          client={dam_client}
                          variables={{
                            module_name:"Media",
                            external_parent_id: 0
                          }}
                          query={GET_DAM}
                        >
                          {({ loading, error, data }) => {

                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              const optionsValues_asset = data.assets_collections.assets;

                              return (
                              
                                // Faz outra busca para pegar itens já selecionados
                                <Query
                                  client={lms_client}
                                  variables={{trail_id:this.props.match.params.id}}
                                  query={GET_TRAILHASASSET}
                                >
                                  {({ loading, error, data }) => {
        
                                    if (loading) return <tr><td>Carregando...</td></tr>;
                                    if (error) return <tr><td>Erro :(</td></tr>;
        
                                      const defaultValues_assets = [];
                                      // Como os Assets estão em banco difererente da trilha, pega o nome dele pela busca anterior
                                      data.trailhasasset.trailhasassets.forEach((item) => {
                                        optionsValues_asset.forEach(function(i){
                                          if(item.asset_id === i.value) {
                                            defaultValues_assets.push(i);
                                          }
                                        });
                                      });;

                                      return (
                                      
                                            <tr>
                                              <td>
                                              <Select
                                                defaultValue={defaultValues_assets}
                                                isMulti
                                                name={"value"}
                                                options={optionsValues_asset}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={this.onChange_asset}
                                                isClearable={false}
                                                onInputChange={this.onInputChange}
                                              />
                                              </td>
                                            </tr>
                                          
                                          );
                                }}

                            </Query>
                            );
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
              </Col>
          </Row>


          <Row>
              <Col md={12}>
              <Card
                title="Organizar a trilha"
                category="Clique e arraste para organizar"
                ctTableFullWidth
                ctTableResponsive
                content={
                      <Query
                          client={lms_client}
                          variables={{trail_id: parseInt(this.props.match.params.id)}}
                          query={GET_TRAILITEMS}
                        >
                          {({ loading, error, data }) => {

                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              const trailitems = data.trailitems.trailitems;
                              if(this.start_sortable) {
                                this.start_sortable = false;
                                this.sortableItems(trailitems);
                              }
                              
                              return (
                                  <div className="sortable">
                                    <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
                                  </div>
                                );
                              }}
                            </Query>
                }
                />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TrailHasCourse;
